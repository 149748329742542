import {
  ListViewProvider,
  useListView,
} from '../../../data-administration/data-admininstration-list/core/ListViewProvider'
import {QueryRequestProvider} from '../../../data-administration/data-admininstration-list/core/QueryRequestProvider'
import {QueryResponseProvider} from '../../../data-administration/data-admininstration-list/core/QueryResponseProvider'
import {UsersListHeader} from '../../../data-administration/data-admininstration-list/components/header/UsersListHeader'
import {UsersTable} from '../../../data-administration/data-admininstration-list/table/UsersTable'
import {UserEditModal} from '../../../data-administration/data-admininstration-list/form-edit-modal/CoomonEditModal'
import {KTCard, allCategory} from '../../../../../../_metronic/helpers'
import {roleColumns} from './_columns'

const UsersList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <KTCard>
      <UsersListHeader placeholder='Search Sponsor' label='Add Sponsor' />
      <UsersTable columnProps={roleColumns} />
      <UserEditModal formName='CreidtBuyerForm' />
    </KTCard>
  )
}

const CreditListWrapper = () => (
  <QueryRequestProvider initialValue={{type: 'SPONSOR', initialApi: 'users'}}>
    <QueryResponseProvider>
      <ListViewProvider>
        <UsersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CreditListWrapper}
