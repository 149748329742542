import {Column} from 'react-table'
import {columnGenerators} from '../../../../../../_metronic/helpers'

const header = [
  {
    label: 'Content Type',
    value: 'contentType',
  },
  {
    label: 'URL',
    value: 'title',
  },
  {
    label: 'Description',
    value: 'description',
  },
  {
    label: 'Banner',
    value: 'url',
  },
]

const roleColumns: ReadonlyArray<Column<any>> = columnGenerators(header)

export {roleColumns}
