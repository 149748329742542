import {KTCard} from '../../../../../../_metronic/helpers'
import {Card4} from '../../../../../../_metronic/partials/content/cards/Card4'
import {UsersListHeader} from '../../../data-administration/data-admininstration-list/components/header/UsersListHeader'
import {ListViewProvider} from '../../../data-administration/data-admininstration-list/core/ListViewProvider'
import {QueryRequestProvider} from '../../../data-administration/data-admininstration-list/core/QueryRequestProvider'
import {QueryResponseProvider} from '../../../data-administration/data-admininstration-list/core/QueryResponseProvider'
import {UserEditModal} from '../../../data-administration/data-admininstration-list/form-edit-modal/CoomonEditModal'
import {UsersTable} from '../../../data-administration/data-admininstration-list/table/UsersTable'
import {roleColumns} from './_columns'

const UsersList = () => {
  return (
    <KTCard>
      <UsersListHeader placeholder='Search name' label='Add New' />
      <UsersTable columnProps={roleColumns} />
      {<UserEditModal formName='WeightScaleModalForm' />}
    </KTCard>
  )
}

const WeightScaleWrapper = () => (
  <QueryRequestProvider initialValue={{initialApi: 'weightscales'}}>
    <QueryResponseProvider>
      <ListViewProvider>
        <UsersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {WeightScaleWrapper}
