import React, {useEffect, useRef} from 'react'
import {Chart} from 'react-google-charts'

export const options = {}

export function SankyWidget({
  label,
  data = [
    ['From', 'To', 'Weight'],
    ['Indorama', 'Pet Value Inc.', 35043.5],
    ['Indorama', 'Central MRF & Recycling', 21000.0],
    ['Indorama', 'Shakti Plastics', 321.0],
    ['Indorama', 'One more MRF', 1.0],
    ['Indorama', 'New Testin', 35000.0],
    ['Indorama', '78989', 25.0],
  ],
}) {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const resizeChart = () => {
      if (chartRef.current) {
        chartRef.current.style.width = '100%'
        chartRef.current.style.height = '100%'
      }
    }

    // Initial resize on load
    resizeChart()

    // Add event listener for window resize
    window.addEventListener('resize', resizeChart)

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', resizeChart)
    }
  }, [])

  return (
    <>
      <div style={{padding: '0.5rem', textAlign: 'center', fontWeight: 'bold'}}>{label}</div>
      <div ref={chartRef} style={{minHeight: '400px', width: '100%'}}>
        <Chart chartType='Sankey' width='100%' height='100%' data={data} options={options} />
      </div>
    </>
  )
}
