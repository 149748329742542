import {Column} from 'react-table'
import {columnGenerators} from '../../../../../../_metronic/helpers'

const header = [
  {
    label: 'S.N',
    value: 'id',
  },
  {
    label: 'Company Name',
    value: 'companyDetails[name]',
  },
  {
    label: 'Address',
    value: 'address[state]',
  },
  {
    label: 'Country',
    value: 'address[country]',
  },
  {
    label: 'Representative Name',
    value: 'name',
  },
  {
    label: 'Mobile',
    value: 'personalDetails[mobile]',
  },
  {
    label: 'Email',
    value: 'personalDetails[email]',
  },
  {
    label: 'Logo',
    value: 'bussinessPermit',
  },
  {
    action: ['edit', 'delete'],
  },
]

const roleColumns: ReadonlyArray<Column<any>> = columnGenerators(header)

export {roleColumns}
