import {Column} from 'react-table'
import {columnGenerators} from '../../../../../../_metronic/helpers'

const header = (isFranchise = false) => [
  {label: 'S.N', value: 'id'},
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Transaction ID',
    value: 'transactionId',
  },

  {
    label: 'Type',
    value: 'transactionType',
  },
  {
    label: 'Supplied Qty',
    value: 'supplied',
  },
  {
    label: 'Amount',
    value: 'amount',
  },
  {
    label: 'Proof of Payment',
    value: 'bussinessPermit',
  },
]

const roleColumns = (isFranchise = false) => {
  return columnGenerators(header(isFranchise))
}
export {roleColumns}
