import {Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {SettingsMenu} from './SettingsMenu'
import ProtectedSpecificRoute from '../../../routing/NestedPrivateRoutes'
import {ContentWrapper} from './settting-modules/content/contentWrapperList'
import {CategoryWrapper} from './settting-modules/category/categoryList'
import {PricingWrapper} from './settting-modules/pricing/pricingList'
import {ProcessingRoutesWrapper} from './settting-modules/processing-routes/processingRouteList'
import {SubCategoryWrapper} from './settting-modules/sub-category-wrapper/subCategoryList'
import {CertificationsManagementWrapper} from './settting-modules/certifications/certificationsList'
import {WeightScaleWrapper} from './settting-modules/weight-scale/weight'
import {AuditorWrapper} from './settting-modules/auditor/auditorList'
import {IncentiveList} from './settting-modules/incentive/incentiveList'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../../../_metronic/helpers'
import {FC, Suspense} from 'react'
import TopBarProgress from 'react-topbar-progress-indicator'
import {CreditListWrapper} from './settting-modules/credit-buyer/creditList'
import {ProductTestingWrapper} from './settting-modules/product-testing/proudctTestingList'
import {StandardWrapper} from './settting-modules/standard/standardWrapperList'
import {PointOfOriginListWrapper} from './settting-modules/pointOfOrigin/pointOfOriginList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Settings',
    path: '/apps/settings',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SettingPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<SettingsMenu />} />
        <Route
          path='content'
          element={
            <SuspensedView>
              {/* <ProtectedSpecificRoute> */}
              <PageTitle breadcrumbs={usersBreadcrumbs}>Content</PageTitle>
              <ContentWrapper />
              {/* </ProtectedSpecificRoute> */}
            </SuspensedView>
          }
        />
        <Route
          path='category'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Category</PageTitle>
              <CategoryWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='audit-standard'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Standard</PageTitle>
              <StandardWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='certifications'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Certifications</PageTitle>
              <CertificationsManagementWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='items'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Items</PageTitle>
              <SubCategoryWrapper />
            </SuspensedView>
          }
        />

        <Route
          path='product-testing'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Product Testing</PageTitle>
              <ProductTestingWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='pricing'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Pricing</PageTitle>
              <PricingWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='incentives'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Incentives</PageTitle>
              <IncentiveList />
              {/* <OceanHubWrapper /> */}
            </>
          }
        />
        <Route
          path='processing-routes'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Processing Routes</PageTitle>
              <ProcessingRoutesWrapper />
            </>
          }
        />
        {/* VehicleDetailsWrapper */}
        <Route
          path='weight-scale'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Weight Scale</PageTitle>
              {/* <VehicleDetailsWrapper /> */}
              <WeightScaleWrapper />
            </>
          }
        />
        <Route
          path='auditor'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Auditor</PageTitle>
              <AuditorWrapper />
            </>
          }
        />
        <Route
          path='credit-buyer'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Credit Buyer</PageTitle>
              <CreditListWrapper />
            </>
          }
        />
        <Route
          path='point-of-origin'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Point of Origin</PageTitle>
              <PointOfOriginListWrapper />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export default SettingPage
