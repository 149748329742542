import {Column} from 'react-table'
import {columnGenerators} from '../../../../../../_metronic/helpers'

const header = [
  {
    label: 'S.N',
    value: 'id',
  },
  {
    label: 'Test Name',
    value: 'testName',
  },
  /*  {
    label: 'Display Name',
    value: 'displayName',
  }, */
  {
    label: 'Parameter',
    value: 'parameter',
  },
  {
    label: 'Target Value',
    value: 'standard',
  },
  {
    label: 'Minimum',
    value: 'minimum',
  },
  {
    label: 'Maximum',
    value: 'maximum',
  },
  {
    label: 'Standard',
    value: 'targetValue',
  },
  /*  {
    label: 'Applicable SKUs',
    value: 'itemName',
  }, */
  {
    action: ['edit'],
  },
]

const roleColumns: ReadonlyArray<Column<any>> = columnGenerators(header)

export {roleColumns}
