import {Column} from 'react-table'
import {columnGenerators} from '../../../../../../_metronic/helpers'

const header = [
  {
    label: 'S.N',
    value: 'id',
  },
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Display Name',
    value: 'displayName',
  },
  {
    label: 'Icon',
    value: 'avatar',
  },
  {
    label: 'Grade',
    value: 'gradeId',
  },
  {
    label: 'Color',
    value: 'colorId',
  },
  {
    label: 'GHG Saving',
    value: 'co2Savings',
  },
  {
    label: 'BOM',
    value: 'bom',
  },
  {
    label: 'QC Test',
    value: 'test',
  },
  {
    action: ['edit'],
  },
]

const roleColumns: ReadonlyArray<Column<any>> = columnGenerators(header)

export {roleColumns}
