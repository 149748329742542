import {KTCard} from '../../../../_metronic/helpers'
import {Card4} from '../../../../_metronic/partials/content/cards/Card4'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import React from 'react'
import {useAuth} from '../../auth'

const features = (isPartner = false) => [
  ...(isPartner
    ? [
        {name: 'Credit Buyer', icon: 'Credit Buyer', to: 'credit-buyer'},
        {name: 'Standard', icon: 'Standard', to: 'audit-standard'},
      ]
    : [
        {name: 'Content', icon: 'content', to: 'content'},
        {name: 'Category', icon: 'category', to: 'category'},
        {name: 'Items', icon: 'item', to: 'items'},
        {name: 'Pricing', icon: 'price', to: 'pricing'},
        {name: 'Incentives', icon: 'incentive', to: 'incentives'},
        {name: 'Processing Routes', icon: 'process_settings page', to: 'processing-routes'},
        {name: 'Weight Scale', icon: 'weightscale', to: 'weight-scale'},
        {name: 'Certifications', icon: 'certificate', to: 'certifications'},
        {name: 'Auditor', icon: 'auditor', to: 'auditor'},
        {name: 'Product Testing', icon: 'product-testing', to: 'product-testing'},
        {name: 'Point of Origin', icon: 'point of origin', to: 'point-of-origin'},
        // {name: 'Credit Buyer', icon: 'Credit Buyer', to: 'credit-buyer'},
      ]),
]

const Menu = () => {
  const {isPartner} = useAuth()
  console.log({isPartner})
  return (
    <KTCard addCard={false}>
      <div className='row g-xl-4'>
        {features(isPartner).map((eachFeatures, eachIndex) => (
          <div key={eachIndex + 1 + ''} className='col col-lg-2'>
            <Link className={clsx('menu-link without-sub')} to={eachFeatures.to}>
              <Card4
                // icon={`/media/svg/files/${eachFeatures.icon}.png`}
                icon={`/media/svg/files/${eachFeatures.icon}.png`}
                title={eachFeatures.name}
                description=''
              />
            </Link>
          </div>
        ))}
      </div>
    </KTCard>
  )
}

const SettingsMenu = () => <Menu />

export {SettingsMenu}
