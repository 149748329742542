import {useListView} from '../../core/ListViewProvider'
import {UsersListToolbar} from './UserListToolbar'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'

interface userProps {
  label?: string
  placeholder?: string
  searchElements?: any
  showResetButton?: boolean
  initialApi?: any
  showDownload?: boolean
  showPayNow?: boolean
}

const UsersListHeader = ({
  label = '',
  showResetButton = false,
  placeholder,
  searchElements = [],
  initialApi = '',
  showDownload = false,
  showPayNow = false,
}: userProps) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0'>
      <UsersListSearchComponent
        showPayNow={showPayNow}
        showDownload={showDownload}
        showResetButton={showResetButton}
        searchElements={searchElements}
        placeholder={placeholder}
        initialApi={initialApi}
      />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />}
        <UsersListToolbar label={label} />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UsersListHeader}
