import {Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import React, {FC, Suspense} from 'react'
import {WasteCollectorMenu} from './WasteCollectorMenu'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../../../_metronic/helpers'
import {UserManagementWrapper} from '../data-administration/data-admininstration-list/user-management/userManagementList'
import {WasteCollectorPaymentList} from '../data-administration/data-admininstration-list/waste-collector-payment/wasteCollectorPaymentList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Transactions',
    path: '/apps/waste-source',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const WasteCollectorPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<WasteCollectorMenu />} />
        <Route
          path='waste-collector'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Waste Collector</PageTitle>
              <UserManagementWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='payments'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Payments</PageTitle>
              <WasteCollectorPaymentList />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export default WasteCollectorPage
