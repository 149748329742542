/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useRef, useState} from 'react'
import {
  generateAddress,
  configCategoryMap,
  toAbsoluteUrl,
  QUERIES,
} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import ImageLightBox from '../../../../../../../_metronic/helpers/components/ImageLightBox'
import {ViewTableModal} from './ViewTableModal'
import {MapImageModal} from './MapImageModal'
import {useAuth} from '../../../../../auth'
import {useMutation, useQueryClient} from 'react-query'
import {updateUser} from '../../core/_requests'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {errorToast, successToast} from '../../../../../../../_metronic/helpers/components/Toaster'
import {HelloTesting} from '../../../../../../../_metronic/helpers/returnDataHelper'
import {useLocation} from 'react-router-dom'

type Props = {
  user: any
  showImage?: boolean
  mapData?: string
  showImageOnly?: boolean
}

const UserInfoCell: FC<Props> = ({
  user,
  showImageOnly = false,
  showImage = false,
  mapData = '',
}) => {
  const lightBoxRef = useRef<any>(null)

  const {pathname} = useLocation()

  const {isPartner} = useAuth()
  const {itemIdForUpdate} = useListView()
  const ReturnData = () => {
    const queryClient = useQueryClient()
    const {query} = useQueryResponse()

    const assignAuditor = useMutation(
      (id) => updateUser([user.productionId], `productions/assignAuditor`),
      {
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: (d: any) => {
          if (d?.[0]?.message) {
            errorToast(d[0].message)
          }
          // successToast('Success')
          // ✅ update detail view directly
          queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
        },
      }
    )

    const [showImageModal, setshowImageModal] = useState({
      show: false,
      clicked: '',
    })
    const [showMapModal, setShowMapModal] = useState({
      show: false,
      clicked: '',
    })
    if (showMapModal.show) {
      return (
        <MapImageModal
          close={() => setShowMapModal({show: false, clicked: ''})}
          data={user}
          name={showMapModal.clicked}
        />
      )
    }

    if (showImageModal.show) {
      return (
        <ViewTableModal
          close={() => setshowImageModal({show: false, clicked: ''})}
          data={user}
          name={showImageModal.clicked}
        />
      )
    }
    // if (mapData.includes('[')) return clipText(accessNestedProperty(user, mapData), 30)
    return HelloTesting({
      user,
      mapData,
      pathname,
      isPartner,
      lightBoxRef,
      assignAuditor,
      setShowMapModal,
      setshowImageModal,
    })
  }
  return (
    <div className='align-items-center'>
      {showImageOnly && (
        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
          <div className='symbol-label'>
            <img
              src={user.icon || user.logo || toAbsoluteUrl(`/media/${user.avatar}`)}
              alt={user.name}
              className='w-100'
              onClick={() =>
                lightBoxRef?.current?.open(
                  user.icon || user.logo || toAbsoluteUrl(`/media/${user.avatar}`),
                  itemIdForUpdate
                )
              }
            />
          </div>
          {user.id && mapData && <ImageLightBox ref={lightBoxRef} />}
        </div>
      )}
      {showImage && (
        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
          <a href='#'>
            {user.avatar ? (
              <div className='symbol-label'>
                <img
                  src={user.icon || toAbsoluteUrl(`/media/${user.avatar}`)}
                  alt={user.name}
                  className='w-100'
                />
              </div>
            ) : (
              <div
                className={clsx(
                  'symbol-label fs-3',
                  `bg-light-${user.initials?.state}`,
                  `text-${user.initials?.state}`
                )}
              >
                {user.initials?.label}
              </div>
            )}
          </a>
        </div>
      )}
      {!showImageOnly && (
        <div className='d-flex flex-column'>
          <a href='#' className='text-gray-800 text-hover-primary mb-1'>
            {ReturnData()}
          </a>
        </div>
      )}
    </div>
  )
}

function clipText(text, maxLength = 25, clipper = '...') {
  if (!text) return text
  return text.length > maxLength ? text.slice(0, maxLength - clipper.length) + clipper : text || '-'
}

export {UserInfoCell}
