import {Column} from 'react-table'
import {columnGenerators} from '../../../../../../_metronic/helpers'

export const header = [
  {
    label: 'S.N',
    value: 'S.N',
  },

  {
    label: 'Date',
    value: 'createdAt',
  },
  {
    label: 'Batch Id',
    value: 'id',
  },
  {
    label: 'From Facility',
    value: 'pickupInfo[name]',
  },
  /*  {
    label: 'Franchise',
    value: '-',
  },
  {
    label: 'Address',
    value: 'pickup_address',
  }, */
  {
    label: 'Category',
    value: 'categoryName',
  },
  /* {
    label: 'Icon',
    value: 'categoryIcon',
  }, */
  {
    label: 'Item',
    value: 'pickup_quantity_item',
  },
  {
    label: 'Quantity',
    value: 'pickup_quantity',
  },
  {
    label: 'To Facility',
    value: 'pickupInfo[centreName]',
  },
  {
    label: 'Delivered On',
    value: 'completedAt',
  },
  {
    label: 'Status',
    value: 'status',
  },

  {
    label: 'certificate_view',
  },
  {
    action: ['qr'],
  },
]

const roleColumns: ReadonlyArray<Column<any>> = columnGenerators(header)

export {roleColumns}
