/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {PaginationState} from '../../../../../../../_metronic/helpers'
import React, {useEffect, useMemo, useState} from 'react'
import ReactPaginate from 'react-paginate'

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

const UsersListPagination = ({initialApi}: any) => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState, state} = useQueryRequest()
  const [options, setOptions] = useState([state.size])
  // const updatePage = (page: number | undefined | null) => {
  //   if (!page || isLoading || pagination.page === page) {
  //     return
  //   }
  //   updateState({page, size: pagination.size || 10})
  // }
  useEffect(() => {
    if (pagination.totalItems) {
      const generateOptions = (total: number): number[] => {
        const baseOptions: number[] = [10, 50, 100, 200, 300, 500, 800, 1100]
        let filteredOptions: number[] = []
        // Add options that are less than or equal to totalItems
        for (let i = 0; i < baseOptions.length; i++) {
          if (baseOptions[i] <= total) {
            filteredOptions.push(baseOptions[i])
          } else {
            // If the current option exceeds totalItems, add the next higher option if applicable
            if (filteredOptions.length > 0) {
              filteredOptions.push(baseOptions[i]) // Add the next higher option
            }
            break // Stop the loop
          }
        }

        return filteredOptions
      }

      const dynamicOptions = generateOptions(pagination.totalItems)
      if (dynamicOptions.length) {
        setOptions(dynamicOptions)
      }
    }
  }, [pagination])

  const handlePagination = (page: number) => {
    if (isLoading) {
      return
    }
    updateState({page: page - 1, ...((initialApi && {initialApi}) || {})})
  }
  const handlePerPage = (event: any) => {
    const perPage = event.target.value
    if (isLoading) {
      return
    }
    updateState({size: perPage, ...((initialApi && {initialApi}) || {})})
  }
  return (
    <div className='row'>
      {pagination.totalPages > 0 && (
        <div className='row-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
          <div className='col-4'>
            <label className='fs-4'>Rows Per Page : </label>
          </div>
          <div className='col-2'>
            <select
              value={state.size}
              onChange={(e: any) => handlePerPage(e)}
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
            >
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div className='table-pagination mt-4'>
          {(pagination.totalPages && (
            <ReactPaginate
              nextLabel={'Next'}
              breakLabel={'...'}
              previousLabel={'Previous'}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              activeClassName={'active'}
              breakClassName={'break-me'}
              // forcePage={currentPage - 1 || 0}
              containerClassName={'pagination'}
              disabledLinkClassName={'false'}
              // onPageChange={handlePageClick}
              onPageChange={(page: any) => handlePagination(page.selected + 1)}
              // onPageChange={(page: any) => handlePagination(page.selected + 1, perPage)}
              // pageCount={totalItems === 0 ? 1 : Math.ceil(totalItems / perPage)}
              pageCount={pagination.totalPages}
            />
          )) ||
            null}
        </div>
      </div>
    </div>
  )
}

export {UsersListPagination}
