import {KTCard} from '../../../../_metronic/helpers'
import {Card4} from '../../../../_metronic/partials/content/cards/Card4'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import React from 'react'

const features = [
  {name: 'Waste Collector', icon: 'account', to: 'waste-collector'},
  {name: 'Payments', icon: 'buyer', to: 'payments'},
]

const Menu = () => {
  return (
    <KTCard addCard={false}>
      <div className='row g-xl-4'>
        {features.map((eachFeatures, eachIndex) => (
          <div key={eachIndex + 1 + ''} className='col col-lg-2'>
            <Link className={clsx('menu-link without-sub')} to={eachFeatures.to}>
              <Card4
                // icon={`/media/svg/files/${eachFeatures.icon}.png`}
                icon={`/media/svg/files/${eachFeatures.icon}.png`}
                title={eachFeatures.name}
                description=''
              />
            </Link>
          </div>
        ))}
      </div>
    </KTCard>
  )
}

const WasteCollectorMenu = () => <Menu />

export {WasteCollectorMenu}
