import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {UsersListHeader} from '../components/header/UsersListHeader'
import {UsersTable} from '../table/UsersTable'
import {UserEditModal} from '../form-edit-modal/CoomonEditModal'
import {KTCard, initialQueryState} from '../../../../../../_metronic/helpers'
import {Card4} from '../../../../../../_metronic/partials/content/cards/Card4'
import {roleColumns} from './_columns'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {useAuth} from '../../../../auth'
import {getUserById} from '../core/_requests'

const DropOffPointList = () => {
  const {auth} = useAuth()
  const hideDropdown = auth?.data?.userType === 'SMART_CENTRE' || false
  const {data} = useQuery(
    `users?page=0&size=10&type=FRANCHISE`,
    () => getUserById('', 'users?page=0&size=10&type=FRANCHISE'),
    {
      enabled: hideDropdown,
      cacheTime: 0,
      onError: (err) => {
        console.warn(err)
      },
    }
  )
  const {updateState} = useQueryRequest()
  const [categoriesList, setCategoriesList] = useState([{label: 'Select One', value: ''}])
  useEffect(() => {
    if (data && data.length) {
      let dropdownData: any = [{label: 'Select One', value: ''}]
      data.map((eachData) => {
        return dropdownData.push({label: eachData?.personalDetails?.name, value: eachData.id})
      })
      setCategoriesList(dropdownData)
      updateState({
        enabled: true,
        initialApi: `categories/${dropdownData[0].value}/items`,
        ...initialQueryState,
      })
    }
  }, [data])
  const statusLis = [
    {label: 'Select One', value: ''},
    {label: 'CREATED', value: 'CREATED'},
    {label: 'ACCEPTED', value: 'ACCEPTED'},
    {label: 'REJECTED', value: 'REJECTED'},
    {label: 'COMPLETED', value: 'COMPLETED'},
  ]
  const searchElements = [
    {
      type: 'select',
      value: 'select',
      options: statusLis,
      name: 'status',
      label: 'Status',
    },
    {
      type: 'datePicker',
      name: 'start_date',
      label: 'Start Date',
    },
    {
      type: 'datePicker',
      name: 'end_date',
      label: 'End Date',
    },
    {
      ...(hideDropdown && {
        type: 'select',
        value: 'select',
        options: categoriesList,
        name: 'franchise',
        label: 'Franchise',
      }),
    },
  ]
  return (
    <KTCard>
      <UsersListHeader
        showDownload
        showResetButton={true}
        searchElements={searchElements}
        placeholder='Search Category'
        initialApi='return/orders'
      />
      <UsersTable initialApi='return/orders' columnProps={roleColumns} />
      <UserEditModal showViewPage getByIdApi='return/orders/' formName='ReturnOrdersModalForm' />
    </KTCard>
  )
}

const ReturnOrdersWrapper = () => (
  <QueryRequestProvider initialValue={{initialApi: 'return/orders'}}>
    <QueryResponseProvider>
      <ListViewProvider>
        <DropOffPointList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ReturnOrdersWrapper}
