import {useEffect} from 'react'
import {useListView} from '../../core/ListViewProvider'
import {UserEditModalHeader} from '../../form-edit-modal/UserEditModalHeader'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {SankyWidget} from '../../../../../../../_metronic/partials/widgets'
import {useFetchCommon} from '../../../../../../../_metronic/helpers/crud-helper/useQuery'

interface ModalProps {
  formName?: string
  headerName?: string
  viewPage?: string
}

const ViewTableModal = ({close, data, name}: any) => {
  const {responseData: sankeyData} = useFetchCommon({
    api: `sankey/c2pp/${data.id}`,
    isEnabled: name === 'sankey',
    isDropDown: false,
  })
  console.log({sankeyData, data})
  const configName = {
    PRE_STAGE: 'Before',
    IN_PROGRESS_STAGE: 'In Progress',
    POST_STAGE: 'After',
    LOADING: 'Loading',
    SLIP: 'Slip',
  }
  // const filterImage = data.filter((x) => x.imageType === name) || []
  const isWeightscaleTable = name === 'weightscale_table'
  const headerOne =
    name === 'bom'
      ? [
          {label: 'S.N', name: 'id'},
          {label: 'Category', name: 'certificationName'},
          {label: 'Item', name: 'issuingAuthorities'},
          {label: 'Percentage', name: 'validTill'},
        ]
      : !isWeightscaleTable
      ? [
          {label: 'S.N', name: 'id'},
          {label: 'Certification', name: 'certificationName'},
          {label: 'Issued By', name: 'issuingAuthorities'},
          {label: 'Certification No', name: 'certificationNo'},
          {label: 'Valid Till', name: 'validTill'},
          {label: 'Logo', name: 'logo'},
        ]
      : [
          {label: 'S.N', name: 'id'},
          {label: 'Make', name: 'make'},
          {label: 'Model', name: 'model'},
          {label: 'Serial Number', name: 'productSerialNo'},
          {label: 'Capacity', name: 'capacity'},
          {label: 'Calibrated On', name: 'calibratedOn'},
          {label: 'Next Due', name: 'nextDue'},
        ]
  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        {/* <div className='modal-dialog modal-dialog-centered mw-650px'> */}
        <div
          className={`modal-dialog 
           mw-850px modal-dialog-centered`}
        >
          {/* begin::Modal content */}
          <div className='modal-content'>
            {/* <UserEditModalHeader headerName={'Confirmation'} /> */}
            <div className='modal-header'>
              <h5 className='modal-title'>
                {name === 'sankey' ? data?.personalDetails?.name : configName[name]}
              </h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={close}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                }}
              >
                {name === 'sankey' ? (
                  <SankyWidget label='' data={sankeyData} />
                ) : (
                  <>
                    {' '}
                    <table className='table table-hover table-rounded table-striped border gy-4 gs-7 table-bordered'>
                      <thead>
                        {/* <tr className='fw-bold text-gray-800 border-bottom-2 border-gray-200 text-center'> */}
                        <tr className='fw-bold text-gray-800 border-bottom-2 border-gray-200 text-center'>
                          {headerOne.map((eachHeader, eachIn) => (
                            <th key={eachIn + 1 + ''}>{eachHeader.label}</th>
                          ))}
                        </tr>
                        <tr className='fw-bold text-gray-800 border-bottom-2 border-gray-200 text-center'></tr>
                      </thead>
                      <tbody>
                        {isWeightscaleTable
                          ? headerOne?.map((header, index) => (
                              <td className='text-gray-800 text-center' key={index}>
                                {header.name === 'id'
                                  ? index + 1 + ''
                                  : ['calibratedOn', 'nextDue'].includes(header?.name)
                                  ? new Date(
                                      data?.weightScale?.[header?.name]
                                    )?.toLocaleDateString()
                                  : data?.weightScale?.[header?.name]}
                              </td>
                            ))
                          : name === 'bom'
                          ? data.bomDetails.map((x, ind) => (
                              <tr className='text-gray-800 text-center' key={ind + 1}>
                                <td>{ind + 1 + ''}</td>
                                <td>{x.categoryName}</td>
                                <td>{x.itemName}</td>
                                <td>{x.percentage}</td>
                              </tr>
                            ))
                          : data.certifications?.map((certification, ind) => (
                              <tr className='text-gray-800 text-center' key={ind + 1}>
                                {headerOne.map((header, index) => (
                                  <td key={index}>
                                    {header.name === 'id' ? (
                                      ind + 1
                                    ) : header.name === 'validTill' ? (
                                      new Date(certification[header.name]).toLocaleDateString() // Formatting 'validTill' date
                                    ) : header.name === 'logo' ? (
                                      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                        <div className='symbol-label'>
                                          <img
                                            src={certification?.logo}
                                            alt='logo'
                                            className='w-100'
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      certification[header.name]
                                    )}
                                  </td>
                                ))}
                              </tr>
                            ))}
                        {/* <tr className='text-center'> */}
                        {/*  <td>{new Date(user?.createdAt).toLocaleDateString() || ''}</td>
              <td>{user.id}</td>
              <td>-</td>
              <td>{user?.orderDetails?.quantity}</td>
              <td>
                <a onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}>
                  Click to Download
                </a>
              </td>
              <td>
                <a onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}>
                  Click to Download
                </a>
              </td> */}
                        {/* </tr> */}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
              {(name === 'bom' && (
                <>
                  <br />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      flexWrap: 'wrap',
                      flexDirection: 'row',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      color: 'gray-500',
                    }}
                    className='text-gray-800'
                  >
                    <div className='fw'>
                      Drawing
                      <div>
                        {(data?.drawingImage && (
                          <img
                            style={{height: '125px', width: '150px'}}
                            src={data?.drawingImage || ''}
                            alt={'drawing'}
                          />
                        )) || <div className='text-sm'>No Image Uploaded</div>}
                      </div>
                    </div>
                    <div>
                      Instruction
                      <div>
                        {(data?.drawingImage && (
                          <img
                            style={{height: '125px', width: '150px'}}
                            src={data?.processingInstruction || ''}
                            alt={'instruction'}
                          />
                        )) || <div className='text-sm'>No Image Uploaded</div>}
                      </div>
                    </div>
                  </div>
                </>
              )) ||
                ''}
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                onClick={close}
                className='btn btn-light'
                data-bs-dismiss='modal'
              >
                Close
              </button>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {ViewTableModal}
