import React, {useEffect, useState} from 'react'
import DownloadPDFButton from '../table/columns/DownloadPDFButton'
import {toAbsoluteUrl, generateAddress, capitalizeWords} from '../../../../../../_metronic/helpers'
import {useLocation} from 'react-router'
import {useListView} from '../core/ListViewProvider'
import QRCode from 'qrcode.react'
import {useFetchCommon} from '../../../../../../_metronic/helpers/crud-helper/useQuery'

const CertificateComponent = ({data, passRef}) => {
  const {pathname} = useLocation()
  const {itemIdForUpdate} = useListView()

  const isCollectOrders = pathname === '/apps/transactions/collect-orders'
  const isProcessingRecord =
    pathname.includes('processing-record') ||
    pathname === '/apps/plastic-credits/unaudited' ||
    pathname.includes('/apps/plastic-credits/audited')
  const isAudited = pathname === '/apps/plastic-credits/audited'
  const changeInProcessingRecord =
    (pathname.includes('processing-record') && itemIdForUpdate.showProductionReceipt) || false
  const isSupplyOrders =
    pathname.includes('supply-orders') || pathname.includes('/apps/transactions/items')
  const watermarkStyle: any = {
    position: 'relative', // Required for absolute positioning of watermark
    // backgroundImage: `url(${toAbsoluteUrl('/media/svg/files/blank-image.svg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '20%', // Adjust to your preference
  }
  const {responseData: diversionResponse} = useFetchCommon({
    api: `diversionCertificates/${data.createdAt}`,
    // isEnabled: isProcessingRecord,
    isEnabled: false,
    isDropDown: false,
  })

  const [displayData, setDisplayData] = useState<any>({})
  let addressArray: any = []
  if (data?.pickupPointInfo?.address?.state || data?.[0]?.dropOffPointInfo?.address?.state) {
    const responseAddress =
      data?.pickupPointInfo?.address || data[0]?.dropOffPointInfo?.address || {}
    const excludedProperties = ['latitute', 'longitute']
    const order = ['street', 'city', 'state', 'country']
    for (const key of order) {
      if (!excludedProperties.includes(key)) {
        const value = responseAddress[key] || ''
        if (value !== undefined) {
          addressArray.push(value)
        }
      }
    }
  }
  const formattedAddress = addressArray?.filter((item) => item !== '').join(', ') || ''

  // console.log({data, displayData}, Object.keys(data)?.length)
  useEffect(() => {
    if (data?.length > 0) {
      return setDisplayData(data[0])
    }
    if (Object.keys(data)?.length > 0) {
      return setDisplayData(data)
    }
  }, [data])
  const isReturnOrdersPage =
    pathname.includes('supply-orders') || pathname.includes('/apps/transactions/items')

  const {responseData: facilityList} = useFetchCommon({
    api: 'users?page=0&size=10&type=PICKUP_POINT',
    isEnabled: false,
    isDropDown: false,
  })

  const [facilityData, setFacilityData] = useState<any>({})

  useEffect(() => {
    if (facilityList.length > 0) {
      const value = facilityList.filter((x) => x?.id === displayData?.pickupPointId)
      if (value?.length) {
        setFacilityData(value[0])
      }
    }
  }, [facilityList])

  function formatDate(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hour = String(date.getHours()).padStart(2, '0')
    const minute = String(date.getMinutes()).padStart(2, '0')
    const period = date.getHours() < 12 ? 'am' : 'pm'

    return `${year}-${month}-${day} ${hour}:${minute} ${period}`
  }

  const filterSpecificData = (data, name, key = 'docType', displayData = 'docUrl') => {
    return data?.filter((x) => x[key] === name)?.[0]?.[displayData] || ''
  }
  const diverisonName = [
    {label: 'Business Name', value: displayData?.pickupPointName},
    {
      label: 'Address',
      value: isProcessingRecord ? formattedAddress || '' : displayData?.customerInfo?.mobile,
    },
    {
      label: 'Geo-cordinates',
      value:
        displayData?.pickupPointInfo?.address?.latitute +
          ' , ' +
          displayData?.pickupPointInfo?.address?.longitute || '',
    },
    {
      label: 'Business License No',
      value: isProcessingRecord
        ? filterSpecificData(
            displayData?.pickupPointInfo?.kycDocument,
            'POE',
            'docType',
            'docNumber'
          )
        : displayData?.status,
    },
    {
      label: 'Company Reg No',
      value: isProcessingRecord
        ? filterSpecificData(
            displayData?.pickupPointInfo?.kycDocument,
            'POI',
            'docType',
            'docNumber'
          )
        : (displayData?.latitude || '') + ', ' + (displayData?.longitude || ''),
    },
  ]

  const auditorInformation = [
    {label: 'Audit Company', value: displayData?.auditorInfo?.companyDetails?.name},
    {label: 'Regd. Address', value: displayData?.auditorInfo?.companyDetails?.address},
    {label: 'Auditor Name', value: displayData?.auditorName},
    {label: 'ID', value: displayData?.auditorId},
  ]

  const depositerName = [
    {label: 'Depositor Name', value: displayData?.customerInfo?.name},
    {label: 'Mobile Number', value: displayData?.customerInfo?.mobile},
    {label: 'Source', value: displayData?.source},
    {
      label: 'Collection Date',
      value: new Date(displayData?.collectionDate || new Date()).toLocaleDateString() || '',
    },
    {label: 'Waste Type', value: displayData?.orderDetails?.[0]?.items?.[0]?.remark || ''},
    {
      label: 'Geo-Cordintes',
      value: (displayData?.latitude || '') + ', ' + (displayData?.longitude || ''),
    },
  ]

  const collectionData = [
    {label: 'Business Name', value: displayData?.pickupInfo?.name},
    {label: 'Material Name', value: displayData?.orderDetails?.[0]?.categoryName || ''},
    {
      label: 'Order Date',
      value: new Date(displayData?.createdAt || new Date()).toLocaleDateString() || '',
    },
    {label: 'Plate Number', value: displayData?.vehicleNo || ''},
    {label: 'Status', value: displayData?.status || ''},
    {
      label: 'Geo Co-ordinates',
      value:
        displayData?.pickupInfo?.address?.latitute +
          ' , ' +
          displayData?.pickupInfo?.address?.longitute || '',
    },
  ]

  const receivingFacility = [
    {
      label: 'Business Name',
      value: displayData?.toPickupPointName,
    },
    {
      label: 'Address',
      value: displayData?.centreInfo?.address?.street,
    },
    {
      label: 'Country',
      value: displayData?.centreInfo?.address?.country,
    },
    {
      label: 'Received On',
      value: new Date(displayData?.completedAt || new Date()).toLocaleDateString(),
    },
  ]
  const deliveryDetails = [
    {
      label: 'Recycler Name',
      value: displayData?.centreInfo?.name,
    },
    {
      label: 'Recycler Address',
      value:
        displayData?.centreInfo?.address?.street ||
        '' + '' + displayData?.centreInfo?.address?.city ||
        '' + '' + displayData?.centreInfo?.address?.state ||
        '',
    },
    {
      label: 'Receiving Date',
      value:
        (displayData?.pickupInfo?.pickupCompletedAt &&
          formatDate(new Date(parseInt(displayData.pickupInfo.pickupCompletedAt)))) ||
        '',
    },
  ]

  const lastSection = [
    {label: 'Company Name', value: 'Evergreen Labs Philippines Consulting Inc'},
    {
      label: 'Regd Address',
      value:
        '6th Floor, One Ayala Avenue, Tower 2, Ayala Avenue, Ayala Center, San Lorenzo, City of Makati, Fourth District, National Capital Region (NCR), Philippines, 1223',
    },
  ]

  const subTotal = (data, withMultiply = false) => {
    if (!data) return 0
    return data.reduce(
      (accumulator, expense) =>
        accumulator + (withMultiply ? expense.quantity * expense.price : expense.quantity),
      0
    )
  }

  const diversionInformation = [
    {
      label: 'Date',
      value: new Date(displayData?.createdAt || new Date()).toLocaleDateString() || '',
    },
    {
      label: 'Diversion Type',
      value: displayData?.processName || '',
    },
    {
      label: 'Chain of custody',
      value: 'Batch Traceability',
    },
    {
      label: 'Methodology',
      value: 'ISO 22095 [Mass Balance]',
    },
    ...((!changeInProcessingRecord && [
      {
        label: 'Certificate Type',
        value: isAudited ? displayData.status : 'Unaudited',
      },
    ]) ||
      []),
    {
      label: 'Certificate No',
      value: displayData?.productionId || '',
    },
  ]
  const collectorDetails = [
    {
      label: 'Business Name',
      value: displayData?.centreInfo?.name || '',
    },
    {
      label: 'Address',
      value: generateAddress(displayData?.centreInfo?.address) || '',
    },
    {
      label: 'Country',
      value: displayData?.centreInfo?.address?.country || '',
    },
  ]

  const handleClick = (image) => {
    passRef?.current?.open(image, itemIdForUpdate)
  }
  const downloadPdfUrl =
    process.env.REACT_APP_BASE_API_URL +
    `v1/certificate/download?orderId=${displayData?.id}&type=${
      pathname.includes('collect-orders') ? 'COLLECT' : 'RETURN'
    }`

  const lineStyle = {
    marginTop: '1px',
    borderTop: '1px solid #000', // Adjust the color and width as needed
  }

  const calibrationCertifications = [
    {label: 'Weight Scale No', value: displayData?.weightScale?.productSerialNo},
    {label: 'Calibration On', value: displayData?.weightScale?.calibratedOn},
    {
      label: 'Make',
      value:
        (displayData?.weightScale?.make || '') + ', ' + (displayData?.weightScale?.model || ''),
    },
  ]
  const mapList: any = {
    collection: [
      {label: 'Activity', value: 'processName'},
      {label: 'Batch ID', value: 'date'},
      {label: 'Material', value: 'name'},
      {label: 'Source', value: 'sourceName'},
      {label: 'Quantity', value: 'usedQuantity'},
      {label: 'Facility', value: 'pickupPointName'},
      {label: 'Country', value: 'country'},
    ],
    production: [
      // {label: 'Date', value: 'date'},
      // {label: 'Batch ID', value: 'date'},
      // {label: 'Process Name', value: 'processName'},
      // {label: 'Quantity', value: 'usedQuantity'},
      // {label: 'Country', value: 'country'},
      {label: 'Activity', value: 'processName'},
      {label: 'Batch ID', value: 'date'},
      {label: 'Material', value: 'name'},
      {label: 'Source', value: 'sourceName'},
      {label: 'Quantity', value: 'usedQuantity'},
      {label: 'Facility', value: 'pickupPointName'},
      {label: 'Country', value: 'country'},
    ],
    proofOfDiversion: [
      {label: 'Date', value: 'date'},
      {label: 'Batch ID', value: 'date'},
      {label: 'Process', value: 'process'},
      {label: 'Quantity', value: 'usedQuantity'},
      {label: 'Country', value: 'country'},
    ],
  }
  const dataMap = {
    collection: displayData?.collectionDetails || [],
    production: displayData?.processDetails || [],
    proofOfDiversion: [], // Use an empty array if there’s no data for 'batch'
  }

  const materialDetailsTable = () => {
    return (
      (displayData?.orderDetails?.[0]?.items?.length || isProcessingRecord) && (
        <table
          style={
            {
              // fontFamily: 'Helvetica, Arial, sans-serif',
            }
          }
          className='table table-hover table-rounded  border gy-4 gs-7 '
        >
          <thead>
            <tr className='fw-bold text-gray-800 border-bottom-2 border-gray-200 text-center'>
              <th style={{maxWidth: '1px'}}>S.N</th>
              {
                <>
                  <th style={{maxWidth: isProcessingRecord ? '-1px' : '1px'}}>
                    {isProcessingRecord ? 'Mat. Type' : 'Batch ID'}
                  </th>
                </>
              }
              {!isProcessingRecord && <th style={{}}>Category </th>}
              <th style={{}}>
                {changeInProcessingRecord
                  ? 'Process'
                  : isProcessingRecord
                  ? 'Diversion Type'
                  : !isReturnOrdersPage
                  ? 'Type'
                  : 'Item'}
              </th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {isProcessingRecord
              ? displayData?.inputMaterial?.map((eachData, eachInd) => (
                  <tr key={eachInd + 1 + '-'} className='text-center'>
                    <td>{eachInd + 1 + ''}</td>
                    <td> {eachData?.inputMaterialName} </td>
                    <td>{displayData?.processName}</td>
                    <td>{eachData.inputQuantity}</td>
                  </tr>
                ))
              : displayData?.orderDetails?.[0]?.items?.map((eachData, eachInd) => (
                  <tr key={eachInd + 1 + '-'} className='text-center'>
                    <td>{eachInd + 1 + ''}</td>
                    <td> {displayData?.createdAt} </td>
                    <td>{displayData?.orderDetails?.[0]?.categoryName}</td>
                    <td>{eachData.itemName}</td>
                    <td>
                      {eachData.quantity?.toFixed(2)} {eachData.unit}
                    </td>
                  </tr>
                ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan={isProcessingRecord ? 3 : isReturnOrdersPage ? 4 : 4}
                className='align-end  text-end'
                style={{fontSize: '18px'}}
              >
                Certified Weight
              </td>
              <td className='text-center fw-bold '>
                {isProcessingRecord
                  ? displayData?.inputMaterial?.reduce(
                      (accumulator, expense) => accumulator + +expense.inputQuantity,
                      0
                    ) || 0
                  : subTotal(
                      displayData?.orderDetails?.[0]?.items,
                      isReturnOrdersPage ? false : false
                    )?.toFixed(2)}{' '}
                Kg
              </td>
            </tr>
            {displayData.weightScaleCaliber && (
              <tr>
                <td colSpan={isProcessingRecord ? 3 : isReturnOrdersPage ? 4 : 4}></td>
                <td className='text-center fw-bold ' style={{float: 'right'}}>
                  <img
                    width={60}
                    height={60}
                    style={{
                      backgroundColor: '#FFFFFF',
                      marginTop: '-55px',
                      marginRight: '-28px',
                    }}
                    src={toAbsoluteUrl('/media/misc/Verified.png')}
                    alt='main-logo'
                  />
                </td>
              </tr>
            )}
          </tfoot>
        </table>
      )
    )
  }

  const renderDataRecursively = (items, key) => {
    if (!items || items.length === 0) return null

    return items.map((item, index) => (
      <div key={`${key}-${index}`} style={{marginLeft: index > 0 ? '20px' : '0px'}}>
        {/* Render Table */}
        <table className='table table-hover table-rounded border table-row-dashed gy-4 gs-7'>
          <tbody>
            {mapList[key]?.map(({value, label}, rowIndex) => (
              <tr key={rowIndex} className='text-center cell'>
                <td>{label}</td>
                <td>
                  {label === 'Date'
                    ? item?.[value]
                      ? new Date(item[value] || new Date()).toLocaleDateString()
                      : '-'
                    : item?.[value] ?? '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Render Images */}
        {item?.images?.length > 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginBottom: '22px',
            }}
          >
            {item.images.map((img, imgIndex) => (
              <div
                key={imgIndex}
                className='image-input'
                style={{
                  width: '48%',
                  marginBottom: '20px',
                }}
              >
                <div
                  onClick={() => handleClick(img)}
                  className='image-input-wrapper'
                  style={{
                    width: '100%',
                    paddingBottom: '100%',
                    backgroundImage: `url(${img})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '8px',
                  }}
                ></div>
              </div>
            ))}
          </div>
        )}
        {/* Recursive Rendering for Nested Data */}
        {item.collectionDetails && renderDataRecursively(item.collectionDetails, key)}
        {item.processDetails && renderDataRecursively(item.processDetails, 'production')}{' '}
        {/* Assuming `production` key for processDetails */}
      </div>
    ))
  }

  return (
    <div
      style={{
        padding: '20px',
        width: '100%',
        border: '2px solid black',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${toAbsoluteUrl('/media/misc/top-left.svg')}), url(${toAbsoluteUrl(
          '/media/misc/bottom-right.svg'
        )})`,
        backgroundPosition: 'top left, bottom right', // Set the positions for both images
        backgroundSize: 'auto, auto',
        fontFamily: 'Helvetica, Arial, sans-serif',
      }}
    >
      <div id='pdf-content' className='container' style={watermarkStyle}>
        <div className='row'>
          <div
            className='col-9'
            style={{
              fontSize: '32px',
              fontStyle: 'italic',
              fontWeight: 'bold',
              lineHeight: '40px',
              padding: '21px 20px',
            }}
          >
            <div>
              {isSupplyOrders
                ? 'Dispatch Receipt'
                : changeInProcessingRecord
                ? 'Production Receipt'
                : isProcessingRecord
                ? 'Diversion Certificate'
                : ' Collection Receipt'}
            </div>
            <div></div>
            <hr style={lineStyle} />
          </div>
          <div className='col-3' style={{marginTop: '-20px'}}>
            <img
              style={{backgroundColor: '#FFFFFF', marginTop: '1px'}}
              src={toAbsoluteUrl('/media/logos/enverify-logo-black.png')}
              alt='main-logo'
            />
          </div>
        </div>
        {(displayData?.certifications?.length && (
          <div
            style={{
              display: 'flex',
              float: 'right',
              marginTop: '-28px',
            }}
          >
            {displayData?.certifications?.map((eachImages, eachInd) => (
              <div
                key={eachInd + 1 + ''}
                className='symbol symbol-circle swymbol-50px overflow-hidden me-3'
              >
                <div className='symbol-label'>
                  <img
                    src={`${eachImages.logo || ''}`}
                    alt={'logo'}
                    className='w-100'
                    onClick={() => handleClick(eachImages.logo)}
                  />
                </div>
              </div>
            ))}
          </div>
        )) ||
          null}
        <div
          style={{
            fontFamily: 'Helvetica, Arial, sans-serif',
            padding: '10px 20px',
          }}
        >
          <div
            // className='text-center'
            style={{
              marginTop: '52px',
              color: '#242727',
              fontSize: '18px',
              fontWeight: 'bold',
              // background: 'rgba(177, 227, 227, 0.2)',
              // width: 'fit-content',
              // margin: 'auto',
              // padding: '10px',
            }}
          >
            {changeInProcessingRecord
              ? 'Production Facility'
              : isProcessingRecord
              ? 'Diversion Facility'
              : isReturnOrdersPage
              ? 'Supplying Facility'
              : ' Source of Waste'}
          </div>
          <hr style={lineStyle} />
          <div className='row'>
            <div className='col-9'>
              <div className='row'>
                <div className='col-11'>
                  {(isProcessingRecord
                    ? diverisonName
                    : isReturnOrdersPage
                    ? collectionData
                    : depositerName
                  ).map((eachData, ind) => (
                    <div className='row' key={ind + 1 + ''} style={{fontSize: '14px'}}>
                      <label className='col-lg-4 ' style={{color: '#242727', fontWeight: '700'}}>
                        {eachData.label}
                      </label>
                      <div className='col-lg-8' style={{color: '#4F5152'}}>
                        <span className='fs-6'>: &nbsp;&nbsp;{eachData.value}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='col-3'>
              <QRCode value={downloadPdfUrl} />
            </div>
            <div
              style={{
                marginTop: '20px',
                marginBottom: '-7px',
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            >
              Material Details
            </div>
            {materialDetailsTable()}
            {(isReturnOrdersPage && (
              <>
                <div className='row'>
                  <div className='col-8'>
                    <div className=''>
                      <div
                        className=''
                        style={{
                          fontSize: '18px',
                          fontWeight: 'bold',
                          width: 'fit-content',
                          marginTop: '20px',
                        }}
                      >
                        {' '}
                        Receiving Facility
                      </div>
                      {receivingFacility?.map((eachData, ind) => (
                        <div className='row' key={ind + 1 + ''} style={{fontSize: '14px'}}>
                          <label
                            className='col-lg-4 '
                            style={{color: '#242727', fontWeight: '700'}}
                          >
                            {eachData.label}
                          </label>
                          <div className='col-lg-8' style={{color: '#4F5152'}}>
                            <span className='fs-6'>: &nbsp;&nbsp;{eachData.value}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )) || (
              <div className='row'>
                <div className='col-12'>
                  <div
                    style={{
                      fontSize: '18px',
                      fontWeight: 'bold',
                    }}
                  >
                    {' '}
                    {changeInProcessingRecord
                      ? 'Production Information'
                      : isProcessingRecord
                      ? 'Diversion Information'
                      : 'Facility Information'}
                  </div>
                  <hr style={lineStyle} />
                  <div className='row'>
                    <div className='col-9'>
                      {(isProcessingRecord ? diversionInformation : collectorDetails).map(
                        (eachData, ind) => (
                          <div className='row' key={ind + 1 + ''} style={{fontSize: '14px'}}>
                            <label
                              className='col-lg-4'
                              style={{color: '#242727', fontWeight: '700'}}
                            >
                              {eachData.label}
                            </label>
                            <div className='col-lg-8' style={{color: '#4F5152'}}>
                              <span className='fs-6'>: &nbsp;&nbsp;{eachData.value}</span>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    <div className='col-3'>
                      {isAudited ? (
                        <img
                          style={{backgroundColor: '#FFFFFF', marginTop: '1px'}}
                          src={toAbsoluteUrl('/media/misc/audilogo.png')}
                          alt='main-logo'
                        />
                      ) : (
                        <>
                          <div
                            style={{
                              fontFamily: 'Best Signature Font',
                              fontSize: '22px',
                              marginTop: '-6px',
                            }}
                          >
                            {displayData.createdByName}
                          </div>

                          <div
                            style={{
                              fontSize: '18px',
                              fontWeight: 'bold',
                            }}
                          >
                            Signature
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className='row'>
              <div className='col-12'>
                <div
                  style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    marginTop: '18px',
                    //  fontFamily: 'Helvetica, Arial, sans-serif',
                  }}
                >
                  Calibration
                </div>
                <hr style={lineStyle} />
                <div className='row'>
                  <div className='col-9'>
                    {calibrationCertifications?.map((eachData, ind) => (
                      <div className='row' key={ind + 1 + ''} style={{fontSize: '14px'}}>
                        <label className='col-lg-4' style={{color: '#242727', fontWeight: '700'}}>
                          {eachData.label}
                        </label>
                        <div className='col-lg-8' style={{color: '#4F5152'}}>
                          <span className='fs-6'>: &nbsp;&nbsp;{eachData.value}</span>
                        </div>
                      </div>
                    )) || ''}
                  </div>
                </div>
              </div>
            </div>
            {(!isSupplyOrders && !isCollectOrders && displayData?.certifications?.length && (
              <>
                <div
                  style={{
                    fontSize: '18px',
                    marginTop: '18px',
                    fontWeight: 'bold',
                  }}
                >
                  Certifications
                </div>
                <table className='table table-hover table-rounded  border gy-4 gs-7 '>
                  <thead /* style={{background: '#1C4379'}} */>
                    <tr className='fw-bold text-gray-800 border-bottom-2 border-gray-200 text-center'>
                      <th style={{minWidth: '4px'}}>S.N</th>
                      <th>Certificate Name</th>
                      <th>Number</th>
                      <th>Issued By</th>
                      <th>Issued On</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayData?.certifications?.map((eachCertification, eachInd) => (
                      <tr key={eachInd + 1 + ''} className='text-center'>
                        <td>{eachInd + 1 + ''}</td>
                        <td>{eachCertification.certificationName}</td>
                        <td>{eachCertification.certificationNo}</td>
                        <td>{eachCertification.issuingAuthorities}</td>
                        <td>
                          {eachCertification.issuedOn
                            ? new Date(eachCertification.issuedOn).toLocaleDateString()
                            : '-'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )) ||
              ''}
            {isAudited && (
              <>
                <div className='row'>
                  <div className='col-12'>
                    <div
                      style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        //  fontFamily: 'Helvetica, Arial, sans-serif',
                      }}
                    >
                      {' '}
                      Auditor Information
                    </div>
                    <hr style={lineStyle} />
                    <div className='row'>
                      <div className='col-9'>
                        {auditorInformation.map((eachData, ind) => (
                          <div className='row' key={ind + 1 + ''} style={{fontSize: '14px'}}>
                            <label
                              className='col-lg-4'
                              style={{color: '#242727', fontWeight: '700'}}
                            >
                              {eachData.label}
                            </label>
                            <div className='col-lg-8' style={{color: '#4F5152'}}>
                              <span className='fs-6'>: &nbsp;&nbsp;{eachData.value}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className='col-3'>
                        <div className=''>
                          <img
                            width='100px'
                            height='100px'
                            src={displayData.sign || ''}
                            alt='main-logo'
                          />
                        </div>
                        <div
                          style={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                          }}
                        >
                          Signature
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!changeInProcessingRecord && (
              <div>
                <br />
                <div
                  style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                  }}
                >
                  Chain of Custody
                </div>
                <hr style={lineStyle} />
                <br />
                {isProcessingRecord ? (
                  <>
                    {Object.keys(mapList).map((x) => (
                      <>
                        <div
                          style={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                          }}
                        >
                          {capitalizeWords(x)}
                          <hr />
                          Supply Chain Traceability
                        </div>
                        <hr style={lineStyle} />
                        <br />
                        {x === 'proofOfDiversion' && materialDetailsTable()}
                        {renderDataRecursively(dataMap[x], x)}
                      </>
                    ))}
                  </>
                ) : (
                  ''
                )}
                {displayData?.images?.length ? (
                  <div
                    style={
                      !isProcessingRecord
                        ? {
                            display: 'flex',
                            justifyContent: 'flex-start',
                            marginBottom: '22px',
                          }
                        : {
                            display: 'flex',
                            flexWrap: 'wrap', // Allow wrapping to the next row
                            justifyContent: 'space-between', // Evenly distribute space between images
                            marginBottom: '22px',
                          }
                    }
                  >
                    {displayData.images.map((eachImages, eachInd) => (
                      <div
                        key={eachInd + 1}
                        className='image-input'
                        style={!isProcessingRecord ? {} : {width: '48%', marginBottom: '20px'}}
                      >
                        <div
                          onClick={() => handleClick(eachImages)}
                          className='image-input-wrapper'
                          style={
                            !isProcessingRecord
                              ? {
                                  width: 163,
                                  height: 150,
                                  // backgroundSize: '400px',
                                  marginRight: '15px',
                                  // backgroundImage: `url(${eachImages})`,
                                  backgroundImage: `url(${eachImages})`,
                                }
                              : {
                                  width: '100%', // Full width of the container (48% of the row)
                                  paddingBottom: '100%', // Maintain aspect ratio (square)
                                  backgroundImage: `url(${eachImages})`,
                                  backgroundSize: 'cover', // Ensure image covers the div
                                  backgroundPosition: 'center', // Center the image
                                  backgroundRepeat: 'no-repeat', // Avoid repeating the image
                                  borderRadius: '8px', // Optional: add rounded corners
                                }
                          }
                        ></div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}

            {!changeInProcessingRecord && isProcessingRecord && (
              <>
                <div>
                  <br />
                  <div
                    style={{
                      fontSize: '18px',
                      fontWeight: 'bold',
                    }}
                  >
                    {' '}
                    Proof of Business
                  </div>
                  <hr style={lineStyle} />
                  <br />
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap', // Allow wrapping to the next row
                      justifyContent: 'space-between', // Evenly distribute space between images
                      marginBottom: '22px',
                    }}
                  >
                    {displayData?.pickupPointInfo?.kycDocument?.map((eachImages, eachInd) => (
                      <div
                        key={eachInd + 1}
                        className='image-input'
                        style={{width: '48%', marginBottom: '20px'}}
                      >
                        <div
                          onClick={() => handleClick(eachImages.docUrl)}
                          className='image-input-wrapper'
                          style={
                            !isProcessingRecord
                              ? {
                                  width: 163,
                                  height: 150,
                                  // backgroundSize: '400px',
                                  marginRight: '15px',
                                  // backgroundImage: `url(${eachImages})`,
                                  backgroundImage: `url(${eachImages})`,
                                }
                              : {
                                  width: '100%', // Full width of the container
                                  paddingBottom: '100%', // Maintain aspect ratio (square)
                                  backgroundImage: `url(${eachImages.docUrl})`,
                                  backgroundSize: 'cover', // Ensure the image covers the div
                                  backgroundPosition: 'center', // Center the image
                                  borderRadius: '8px', // Optional: add rounded corners
                                  backgroundRepeat: 'no-repeat', // Avoid repeating the image
                                }
                          }
                        >
                          {/* Temporary check for image */}
                          {/* <img src={eachImages.docUrl} alt="Document" style={{ width: '100%' }} /> */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            {(!isSupplyOrders && !isCollectOrders && displayData?.weightScale?.scannedImageUrl && (
              <div>
                <div
                  style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                  }}
                >
                  {' '}
                  Calibration Record
                </div>
                <hr style={lineStyle} />
                <div
                  onClick={() => handleClick(displayData?.weightScale?.scannedImageUrl || '')}
                  className='image-input-wrapper'
                  style={{
                    width: 300,
                    height: 280,
                    marginRight: '15px',
                    backgroundImage: `url(${displayData?.weightScale?.scannedImageUrl || ''})`,
                  }}
                ></div>
              </div>
            )) ||
              null}
            <div
              style={{
                background: 'white',
                width: 'fit-content',
                margin: '0px auto -22px',
              }}
            >
              <div>
                <img
                  width={100}
                  height={25}
                  src='/media/svg/dashboard/asm-final-2.png'
                  alt='alt-img'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DownloadPDFButton url={downloadPdfUrl} />
    </div>
  )
}

export default CertificateComponent
