import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse} from '../core/QueryResponseProvider'
import {UsersListHeader} from '../components/header/UsersListHeader'
import {UsersTable} from '../table/UsersTable'
import {UserEditModal} from '../form-edit-modal/CoomonEditModal'
import {initialQueryState, KTCard} from '../../../../../../_metronic/helpers'
import {Card4} from '../../../../../../_metronic/partials/content/cards/Card4'
import {roleColumns} from './_columns'
import {useAuth} from '../../../../auth'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {getUserById} from '../core/_requests'

const mockedData = [
  {
    gender: 'Male',
    email: 'ashok@gmail.com',
    fullAddress: 'New Baneshwor, Kathmandu',
    mobile: '9860105561',
    joinDate: '2023/09/05',
    currentLevel: 'Manager',
    pointEarned: 45,
    pointRedeemed: 5,
    name: 'Ashok Karki',
    birthday: '1992/12/22',
  },
  {
    gender: 'Femal',
    email: 'aleshika@gmail.com',
    fullAddress: 'Old Baneshwor, Kathmandu',
    mobile: '9860105560',
    joinDate: '2023/09/06',
    currentLevel: 'Employee',
    pointEarned: 5,
    pointRedeemed: 15,
    name: 'Aleshki Chheri',
    birthday: '1996/06/06',
  },
]

const UserManagementList = () => {
  const {isFranchise, isPartner} = useAuth()

  const {data} = useQuery(`users?type=CUSTOMER`, () => getUserById('', 'users?type=CUSTOMER'), {
    enabled: isFranchise,
    cacheTime: 0,
    onError: (err) => {
      console.warn(err)
    },
  })
  const {updateState} = useQueryRequest()
  const {response}: any = useQueryResponse()

  const [categoriesList, setCategoriesList] = useState([{label: 'Select One', value: ''}])
  useEffect(() => {
    if (data && data?.length) {
      let dropdownData: any = []
      data.map((eachData) => {
        return dropdownData.push({label: eachData?.personalDetails?.name, value: eachData.id})
      })
      setCategoriesList(dropdownData)
      updateState({
        enabled: true,
        initialApi: `user/due/${dropdownData[0].value}`,
        // initialApi: `user/due/66d6a5cbbe6f1469f152c176`,
        ...initialQueryState,
      })
    }
  }, [data])

  const searchElements = [
    {
      type: 'select',
      queryType: 'price',
      value: 'select',
      options: categoriesList,
      label: 'Select Waste Collector',
    },
    {
      type: 'innn',
      placeholder: 'Enter Economic Value',
      label: 'Economic Value',
      value: response?.totalDue || '',
    },
    {
      type: 'innn',
      placeholder: 'Enter Economic Value',
      label: 'Disbursed',
      value: response?.totalPaid || '',
    },
    {
      type: 'innn',
      placeholder: 'Enter Economic Value',
      label: 'Pending Payment',
      value: response?.totalBalance || '',
    },
  ]

  return (
    <KTCard>
      {!isPartner && (
        <UsersListHeader /* showPayNow={true} */ searchElements={searchElements} label='Pay Now' />
      )}
      <UsersTable columnProps={roleColumns(isFranchise)} />
      <UserEditModal formName='WasteCollectorModalForm' />
    </KTCard>
  )
}

const WasteCollectorPaymentList = () => (
  // <QueryRequestProvider initialValue={{type: 'CUSTOMER'}}>
  <QueryRequestProvider initialValue={{enabled: false}}>
    <QueryResponseProvider>
      <ListViewProvider>
        <UserManagementList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {WasteCollectorPaymentList}
