import {Column} from 'react-table'
import {columnGenerators} from '../../../../../../_metronic/helpers'

export const header = [
  {label: 'S.N', value: 'S.N'},
  {
    label: 'Date',
    value: 'createdAt',
  },

  {
    label: 'Batch Id',
    value: 'productionId',
  },
  {
    label: 'Facility',
    value: 'hubName',
  },
  {
    label: 'Franchisee',
    value: 'franchiseName',
  },
  {
    label: 'Process',
    value: 'processName',
  },
  {
    label: 'Type',
    value: 'processType',
  },
  {
    label: 'Input',
    value: 'inputMaterial',
  },
  {
    label: 'Quantity',
    value: 'inMaterialDetailsQuantity',
  },
  {
    label: 'Output',
    value: 'productionItemDetails',
  },
  {
    label: 'Quantity',
    value: 'productionItemDetailsQuantity',
  },
  {
    label: 'Wastage',
    value: 'wastage',
  },

  /*   {
    action: ['certificate'],
  }, */
  {
    action: ['view_prod_certificate'],
  },
]

const roleColumns: ReadonlyArray<Column<any>> = columnGenerators(header)

export {roleColumns}
