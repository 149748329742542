import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {UsersListHeader} from '../components/header/UsersListHeader'
import {UsersTable} from '../table/UsersTable'
import {UserEditModal} from '../form-edit-modal/CoomonEditModal'
import {KTCard, initialQueryState} from '../../../../../../_metronic/helpers'
import {Card4} from '../../../../../../_metronic/partials/content/cards/Card4'
import {roleColumns} from './_columns'
import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {useAuth} from '../../../../auth'
import {getUserById} from '../core/_requests'

const UsersList = () => {
  const {auth} = useAuth()
  const hideDropdown = auth?.data?.userType === 'SMART_CENTRE' || false
  const {data} = useQuery(
    `users?page=0&size=10&type=FRANCHISE`,
    () => getUserById('', 'users?page=0&size=10&type=FRANCHISE'),
    {
      enabled: hideDropdown,
      cacheTime: 0,
      onError: (err) => {
        console.warn(err)
      },
    }
  )
  const {updateState} = useQueryRequest()
  const [categoriesList, setCategoriesList] = useState([{label: 'Select One', value: ''}])
  useEffect(() => {
    if (data && data.length) {
      let dropdownData: any = [{label: 'Select One', value: ''}]
      data.map((eachData) => {
        return dropdownData.push({label: eachData?.personalDetails?.name, value: eachData.id})
      })
      setCategoriesList(dropdownData)
      updateState({
        enabled: true,
        initialApi: `categories/${dropdownData[0].value}/items`,
        ...initialQueryState,
      })
    }
  }, [data])
  const searchElements = [
    {
      type: 'datePicker',
      name: 'start_date',
      label: 'Start Date',
    },
    {
      type: 'datePicker',
      name: 'end_date',
      label: 'End Date',
    },
    {
      ...(hideDropdown && {
        type: 'select',
        value: 'select',
        options: categoriesList,
        name: 'franchise',
        label: 'Franchise',
      }),
    },
  ]
  const {itemIdForUpdate} = useListView()
  return (
    <KTCard>
      {/* <UsersListHeader placeholder='Search Gas Stations' label='Add New' /> */}
      <UsersListHeader
        showDownload
        initialApi='productions'
        showResetButton={true}
        searchElements={searchElements}
        placeholder='Search Category'
      />
      <UsersTable columnProps={roleColumns} initialApi='productions' />
      <UserEditModal
        showViewPage={itemIdForUpdate?.showCertificate ? true : false}
        headerName='Report'
        formName='ProductionManagementModalForm'
      />
      {/* <UserEditModal formName='ShiftGasStationModalForm' /> */}
    </KTCard>
  )
}

const ProcessingRecordList = () => (
  <QueryRequestProvider initialValue={{initialApi: 'productions'}}>
    <QueryResponseProvider>
      <ListViewProvider>
        <UsersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ProcessingRecordList}
