import {
  ListViewProvider,
  useListView,
} from '../../../data-administration/data-admininstration-list/core/ListViewProvider'
import {QueryRequestProvider} from '../../../data-administration/data-admininstration-list/core/QueryRequestProvider'
import {QueryResponseProvider} from '../../../data-administration/data-admininstration-list/core/QueryResponseProvider'
import {UsersListHeader} from '../../../data-administration/data-admininstration-list/components/header/UsersListHeader'
import {UsersTable} from '../../../data-administration/data-admininstration-list/table/UsersTable'
import {UserEditModal} from '../../../data-administration/data-admininstration-list/form-edit-modal/CoomonEditModal'
import {KTCard} from '../../../../../../_metronic/helpers'
import {Card4} from '../../../../../../_metronic/partials/content/cards/Card4'
import {roleColumns} from './_columns'
const mockedData = [
  {id: '1', currencyName: 'US Dollar', shortForm: 'USD', symbol: '$'},
  {id: '2', currencyName: 'Philippine Peso', shortForm: 'PHP', symbol: 'P'},
]
const UsersList = () => {
  return (
    <KTCard>
      <UsersListHeader placeholder='Search name' label='Add New' />
      <UsersTable /* mockedData={mockedData} */ columnProps={roleColumns} />
      <UserEditModal formName='ContentModalForm' />
    </KTCard>
  )
}

const ContentWrapper = () => (
  <QueryRequestProvider initialValue={{initialApi: 'content'}}>
    <QueryResponseProvider>
      <ListViewProvider>
        <UsersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ContentWrapper}
