/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  data?: any
  label?: string
}

const ChartsWidget9: React.FC<Props> = ({className, label, data}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, data))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  return (
    <div className={`card ${className}`}>
      <div className='p-2 text-center fw-bold'>{label}</div>
      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_5_chart' style={{height: '50px'}}></div>
      </div>
    </div>
  )
}

export {ChartsWidget9}

function getChartOptions(height: number, data: any): ApexOptions {
  return {
    series:
      (data && data?.length && data?.map((x) => x?.quantity || x?.quantitySupplied || '')) || [],
    chart: {
      width: 250,
      type: 'pie',
    },
    legend: {
      show: false,
    },
    /*   dataLabels: {
      enabled: true,
      textAnchor: 'start',
      formatter: function (val: any, opt) {
        console.log({val, opt})
        return opt.w.globals.labels[opt.seriesIndex] + ' : ' + parseFloat(val)?.toFixed(2) + ' %'
      },
      offsetX: 0,
    }, */
    labels: (data?.length && data?.map((x) => x?.name || x?.pickupPointName || '')) || [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
            position: 'bottom',
          },
        },
      },
    ],
  }
}
