import {ListViewProvider} from '../../../data-administration/data-admininstration-list/core/ListViewProvider'
import {QueryRequestProvider} from '../../../data-administration/data-admininstration-list/core/QueryRequestProvider'
import {QueryResponseProvider} from '../../../data-administration/data-admininstration-list/core/QueryResponseProvider'
import {UsersListHeader} from '../../../data-administration/data-admininstration-list/components/header/UsersListHeader'
import {UsersTable} from '../../../data-administration/data-admininstration-list/table/UsersTable'
import {UserEditModal} from '../../../data-administration/data-admininstration-list/form-edit-modal/CoomonEditModal'
import {KTCard, initialQueryState} from '../../../../../../_metronic/helpers'
import {roleColumns} from './_columns'

const UsersList = () => {
  const searchElements = [
    {
      type: 'datePicker',
      name: 'start_date',
      label: 'Start Date',
    },
    {
      type: 'datePicker',
      name: 'end_date',
      label: 'End Date',
    },
  ]
  return (
    <KTCard>
      {/* <UsersListHeader placeholder='Search Gas Stations' label='Add New' /> */}
      <UsersListHeader
        showResetButton={true}
        searchElements={searchElements}
        placeholder='Search Category'
        label='Add New'
      />
      <UsersTable columnProps={roleColumns} />
      <UserEditModal headerName='Report' formName='IncentiveModalForm' />
      {/* <UserEditModal formName='ShiftGasStationModalForm' /> */}
    </KTCard>
  )
}

const IncentiveList = () => (
  <QueryRequestProvider initialValue={{initialApi: 'deals'}}>
    <QueryResponseProvider>
      <ListViewProvider>
        <UsersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {IncentiveList}
