import {Column} from 'react-table'
import {columnGenerators} from '../../../../../../_metronic/helpers'

const header = [
  {label: 'S.N', value: 'id'},
  {
    label: 'Collection Location',
    value: 'personalDetails[name]',
  },
  {
    label: 'Geo Cordinates',
    value: 'geoLocation',
  },
  {
    label: 'Address',
    value: 'city',
  },
  {
    action: ['edit'],
  },
]

const roleColumns: ReadonlyArray<Column<any>> = columnGenerators(header)

export {roleColumns}
