import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {SettingsMenu} from '../modules/apps/settings/SettingsMenu'
import SettingPage from '../modules/apps/settings/SettingsPage'
import TransactionsPage from '../modules/apps/transactions/TransactionsPage'
import {useAuth} from '../modules/auth'
import ProtectedSpecificRoute from './NestedPrivateRoutes'
import WasteCollectorPage from '../modules/apps/waste-collector/WasteCollectorPage'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const DataAdministrationPage = lazy(
    () => import('../modules/apps/data-administration/DataAdminstrationPage')
  )
  const OperationManagementPage = lazy(
    () => import('../modules/apps/operations-management/OperationManagementPage')
  )
  const {currentUser}: any = useAuth()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/*  <Route
          path='auth/*'
          element={
            <Navigate to={currentUser?.userType === 'ADMIN' ? '/apps/stock' : '/dashboard'} />
          }
        /> */}
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='data-administration' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/transactions/*'
          element={
            <SuspensedView>
              <TransactionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/settings/*'
          element={
            <SuspensedView>
              <SettingPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/waste-source/*'
          element={
            <SuspensedView>
              <WasteCollectorPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/*'
          element={
            <SuspensedView>
              <DataAdministrationPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/order-management/*'
          element={
            <SuspensedView>
              <OperationManagementPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
