import {useQueryClient, useMutation} from 'react-query'
import {QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {updatedSelectedUsers} from '../../core/_requests'
import {successToast} from '../../../../../../../_metronic/helpers/components/Toaster'
import {useLocation} from 'react-router-dom'

const UsersListGrouping = () => {
  const {selected, setItemIdForUpdate, clearSelected}: any = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  const {pathname} = useLocation()
  const deleteSelectedItems = useMutation(() => updatedSelectedUsers(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      successToast('Updated Successfully')
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      clearSelected()
    },
  })
  const isAudited = pathname === '/apps/plastic-credits/audited'
  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>

      <button
        type='button'
        className='btn btn-primary'
        style={{backgroundColor: '#333333'}}
        onClick={async () =>
          isAudited ? setItemIdForUpdate(selected) : await deleteSelectedItems.mutateAsync()
        }
      >
        {isAudited ? 'Retire' : 'Send for Audit'}
      </button>
    </div>
  )
}

export {UsersListGrouping}
