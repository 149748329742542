import {Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import React, {FC, Suspense} from 'react'
import ProtectedSpecificRoute from '../../../routing/NestedPrivateRoutes'
import {ContentWrapper} from './transactions-modules/content/contentWrapperList'
import {CategoryWrapper} from './transactions-modules/category/categoryList'
import {PricingWrapper} from './transactions-modules/pricing/pricingList'
import {ProcessingRoutesWrapper} from './transactions-modules/processing-routes/processingRouteList'
import {SubCategoryWrapper} from './transactions-modules/sub-category-wrapper/subCategoryList'
import {CertificationsManagementWrapper} from './transactions-modules/certifications/certificationsList'
import {TransactionsMenu} from './TransactionsMenu'
import {CollectOrdersWrapper} from '../data-administration/data-admininstration-list/collect-orders/collectOrdersList'
import {ReturnOrdersWrapper} from '../data-administration/data-admininstration-list/return-orders/returnOrderrsList'
import {ProcessingRecordList} from '../data-administration/data-admininstration-list/processing-record/processingRecodList'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../../../_metronic/helpers'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Transactions',
    path: '/apps/transactions',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TransactionsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<TransactionsMenu />} />
        <Route
          path='collect-orders'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Collect Orders</PageTitle>
              <CollectOrdersWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='processing-record'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Process</PageTitle>
              <ProcessingRecordList />
            </SuspensedView>
          }
        />
        <Route
          path='supply-orders'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Material Transfer</PageTitle>
              <ReturnOrdersWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='items'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={usersBreadcrumbs}>External Sale</PageTitle>
              <SubCategoryWrapper />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export default TransactionsPage
