export interface MessageModel {
  user: number
  type: 'in' | 'out'
  text: string
  time: string
  template?: boolean
}

const defaultMessages: Array<MessageModel> = [
  {
    user: 4,
    type: 'in',
    text: 'How likely are you to recommend our company to your friends and family ?',
    time: '2 mins',
  },
  {
    user: 2,
    type: 'out',
    text: 'Hey there, we’re just writing to let you know that you’ve been subscribed to a repository on GitHub.',
    time: '5 mins',
  },
  {
    user: 4,
    type: 'in',
    text: 'Ok, Understood!',
    time: '1 Hour',
  },
  {
    user: 2,
    type: 'out',
    text: 'You’ll receive notifications for all issues, pull requests!',
    time: '2 Hours',
  },
  {
    user: 4,
    type: 'in',
    text: 'You can unwatch this repository immediately by clicking here: <a href="https://keenthemes.com">Keenthemes.com</a>',
    time: '3 Hours',
  },
  {
    user: 2,
    type: 'out',
    text: 'Most purchased Business courses during this sale!',
    time: '4 Hours',
  },
  {
    user: 4,
    type: 'in',
    text: 'Company BBQ to celebrate the last quater achievements and goals. Food and drinks provided',
    time: '5 Hours',
  },
  {
    template: true,
    user: 2,
    type: 'out',
    text: '',
    time: 'Just now',
  },
  {
    template: true,
    user: 4,
    type: 'in',
    text: 'Right before vacation season we have the next Big Deal for you.',
    time: 'Just now',
  },
]

export interface UserInfoModel {
  initials?: {label: string; state: 'warning' | 'danger' | 'primary' | 'success' | 'info'}
  name: string
  avatar?: string
  email: string
  position: string
  online: boolean
}

const defaultUserInfos: Array<UserInfoModel> = [
  {
    name: 'Emma Smith',
    avatar: 'avatars/300-6.jpg',
    email: 'e.smith@kpmg.com.au',
    position: 'Art Director',
    online: false,
  },
  {
    name: 'Melody Macy',
    initials: {label: 'M', state: 'danger'},
    email: 'melody@altbox.com',
    position: 'Marketing Analytic',
    online: true,
  },
  {
    name: 'Max Smith',
    avatar: 'avatars/300-1.jpg',
    email: 'max@kt.com',
    position: 'Software Enginer',
    online: false,
  },
  {
    name: 'Sean Bean',
    avatar: 'avatars/300-5.jpg',
    email: 'sean@dellito.com',
    position: 'Web Developer',
    online: false,
  },
  {
    name: 'Brian Cox',
    avatar: 'avatars/300-25.jpg',
    email: 'brian@exchange.com',
    position: 'UI/UX Designer',
    online: false,
  },
  {
    name: 'Mikaela Collins',
    initials: {label: 'M', state: 'warning'},
    email: 'mikaela@pexcom.com',
    position: 'Head Of Marketing',
    online: true,
  },
  {
    name: 'Francis Mitcham',
    avatar: 'avatars/300-9.jpg',
    email: 'f.mitcham@kpmg.com.au',
    position: 'Software Arcitect',
    online: false,
  },

  {
    name: 'Olivia Wild',
    initials: {label: 'O', state: 'danger'},
    email: 'olivia@corpmail.com',
    position: 'System Admin',
    online: true,
  },
  {
    name: 'Neil Owen',
    initials: {label: 'N', state: 'primary'},
    email: 'owen.neil@gmail.com',
    position: 'Account Manager',
    online: true,
  },
  {
    name: 'Dan Wilson',
    avatar: 'avatars/300-23.jpg',
    email: 'dam@consilting.com',
    position: 'Web Desinger',
    online: false,
  },
  {
    name: 'Emma Bold',
    initials: {label: 'E', state: 'danger'},
    email: 'emma@intenso.com',
    position: 'Corporate Finance',
    online: true,
  },
  {
    name: 'Ana Crown',
    avatar: 'avatars/300-12.jpg',
    email: 'ana.cf@limtel.com',
    position: 'Customer Relationship',
    online: false,
  },
  {
    name: 'Robert Doe',
    initials: {label: 'A', state: 'info'},
    email: 'robert@benko.com',
    position: 'Marketing Executive',
    online: true,
  },
  {
    name: 'John Miller',
    avatar: 'avatars/300-13.jpg',
    email: 'miller@mapple.com',
    position: 'Project Manager',
    online: false,
  },
  {
    name: 'Lucy Kunic',
    initials: {label: 'L', state: 'success'},
    email: 'lucy.m@fentech.com',
    position: 'SEO Master',
    online: true,
  },
  {
    name: 'Ethan Wilder',
    avatar: 'avatars/300-21.jpg',
    email: 'ethan@loop.com.au',
    position: 'Accountant',
    online: true,
  },
]

const messageFromClient: MessageModel = {
  user: 4,
  type: 'in',
  text: 'Thank you for your awesome support!',
  time: 'Just now',
}

export interface AlertModel {
  title: string
  description: string
  time: string
  icon: string
  state: 'primary' | 'danger' | 'warning' | 'success' | 'info'
}

const defaultAlerts: Array<AlertModel> = [
  {
    title: 'Project Alice',
    description: 'Phase 1 development',
    time: '1 hr',
    icon: 'technology-2',
    state: 'primary',
  },
  {
    title: 'HR Confidential',
    description: 'Confidential staff documents',
    time: '2 hrs',
    icon: 'information-5',
    state: 'danger',
  },
  {
    title: 'Company HR',
    description: 'Corporeate staff profiles',
    time: '5 hrs',
    icon: 'map001',
    state: 'warning',
  },
  {
    title: 'Project Red',
    description: 'New frontend admin theme',
    time: '2 days',
    icon: 'cloud-change',
    state: 'success',
  },
  {
    title: 'Project Breafing',
    description: 'Product launch status update',
    time: '21 Jan',
    icon: 'compass',
    state: 'primary',
  },
  {
    title: 'Banner Assets',
    description: 'Collection of banner images',
    time: '21 Jan',
    icon: 'graph-3',
    state: 'info',
  },
  {
    title: 'Icon Assets',
    description: 'Collection of SVG icons',
    time: '20 March',
    icon: 'color-swatch',
    state: 'warning',
  },
]
export interface LogModel {
  code: string
  state: 'success' | 'danger' | 'warning'
  message: string
  time: string
}

const defaultLogs: Array<LogModel> = [
  {code: '200 OK', state: 'success', message: 'New order', time: 'Just now'},
  {code: '500 ERR', state: 'danger', message: 'New customer', time: '2 hrs'},
  {code: '200 OK', state: 'success', message: 'Payment process', time: '5 hrs'},
  {code: '300 WRN', state: 'warning', message: 'Search query', time: '2 days'},
  {code: '200 OK', state: 'success', message: 'API connection', time: '1 week'},
  {code: '200 OK', state: 'success', message: 'Database restore', time: 'Mar 5'},
  {code: '300 WRN', state: 'warning', message: 'System update', time: 'May 15'},
  {code: '300 WRN', state: 'warning', message: 'Server OS update', time: 'Apr 3'},
  {code: '300 WRN', state: 'warning', message: 'API rollback', time: 'Jun 30'},
  {code: '500 ERR', state: 'danger', message: 'Refund process', time: 'Jul 10'},
  {code: '500 ERR', state: 'danger', message: 'Withdrawal process', time: 'Sep 10'},
  {code: '500 ERR', state: 'danger', message: 'Mail tasks', time: 'Dec 10'},
]

const allCategory = [
  {label: 'Plastic', value: '63f77b6c7dc37d521ee98400'},
  {label: 'Paper', value: '64030413fb330f79375b13f4'},
  {label: 'Metal', value: '64030413fb330f79375b13f5'},
  {label: 'Glass', value: '64030413fb330f79375b13f6'},
  {label: 'E-Waste', value: '64030413fb330f79375b13f7'},
  {label: 'Textile Waste', value: '64030413fb330f79375b13f8'},
  {label: 'Tes', value: '64f7225522737917cfc506d9'},
]

const configCategoryMap = {
  '63f77b6c7dc37d521ee98400': 'Plastic',
  '64030413fb330f79375b13f4': 'Paper',
  '64030413fb330f79375b13f5': 'Metal',
  '64030413fb330f79375b13f6': 'Glass',
  '64030413fb330f79375b13f7': 'E-Waste',
  '64030413fb330f79375b13f8': 'Textile Waste',
  '64f7225522737917cfc506d9': 'Tes',
}

const unitListItem = [
  {label: 'KG', value: 'KG'},
  {label: 'Piece', value: 'PIECE'},
  // {label: 'Gram', value: 'Gram'},
]

const statusList = [
  {label: 'Approved', value: 'Approved'},
  {label: 'Rejected', value: 'Rejected'},
  {label: 'Deleted', value: 'Deleted'},
]

const cityList = [
  {value: 'Plaridel', label: 'Plaridel'},
  {value: 'Malolos', label: 'Malolos'},
  {value: 'Meycauayan', label: 'Meycauayan'},
  {value: 'San Jose Del Monte', label: 'San Jose Del Monte'},
  {value: 'Baliuag', label: 'Baliuag'},
  {value: 'Marilao', label: 'Marilao'},
]

const countryList = [{value: 'Philippine', label: 'Philippines (+63)'}]

const allcountryList = [
  {
    label: 'Afghanistan (+93)',
    dial_code: '+93',
    emoji: '🇦🇫',
    value: 'AF',
  },
  {
    label: 'Aland Islands (+358)',
    dial_code: '+358',
    emoji: '🇦🇽',
    value: 'AX',
  },
  {
    label: 'Albania (+355)',
    dial_code: '+355',
    emoji: '🇦🇱',
    value: 'AL',
  },
  {
    label: 'Algeria (+213)',
    dial_code: '+213',
    emoji: '🇩🇿',
    value: 'DZ',
  },
  {
    label: 'AmericanSamoa (+1684)',
    dial_code: '+1684',
    emoji: '🇦🇸',
    value: 'AS',
  },
  {
    label: 'Andorra (+376)',
    dial_code: '+376',
    emoji: '🇦🇩',
    value: 'AD',
  },
  {
    label: 'Angola (+244)',
    dial_code: '+244',
    emoji: '🇦🇴',
    value: 'AO',
  },
  {
    label: 'Anguilla (+1264)',
    dial_code: '+1264',
    emoji: '🇦🇮',
    value: 'AI',
  },
  {
    label: 'Antarctica (+672)',
    dial_code: '+672',
    emoji: '🇦🇶',
    value: 'AQ',
  },
  {
    label: 'Antigua and Barbuda (+1268)',
    dial_code: '+1268',
    emoji: '🇦🇬',
    value: 'AG',
  },
  {
    label: 'Argentina (+54)',
    dial_code: '+54',
    emoji: '🇦🇷',
    value: 'AR',
  },
  {
    label: 'Armenia (+374)',
    dial_code: '+374',
    emoji: '🇦🇲',
    value: 'AM',
  },
  {
    label: 'Aruba (+297)',
    dial_code: '+297',
    emoji: '🇦🇼',
    value: 'AW',
  },
  {
    label: 'Australia (+61)',
    dial_code: '+61',
    emoji: '🇦🇺',
    value: 'AU',
  },
  {
    label: 'Austria (+43)',
    dial_code: '+43',
    emoji: '🇦🇹',
    value: 'AT',
  },
  {
    label: 'Azerbaijan (+994)',
    dial_code: '+994',
    emoji: '🇦🇿',
    value: 'AZ',
  },
  {
    label: 'Bahamas (+1242)',
    dial_code: '+1242',
    emoji: '🇧🇸',
    value: 'BS',
  },
  {
    label: 'Bahrain (+973)',
    dial_code: '+973',
    emoji: '🇧🇭',
    value: 'BH',
  },
  {
    label: 'Bangladesh (+880)',
    dial_code: '+880',
    emoji: '🇧🇩',
    value: 'BD',
  },
  {
    label: 'Barbados (+1246)',
    dial_code: '+1246',
    emoji: '🇧🇧',
    value: 'BB',
  },
  {
    label: 'Belarus (+375)',
    dial_code: '+375',
    emoji: '🇧🇾',
    value: 'BY',
  },
  {
    label: 'Belgium (+32)',
    dial_code: '+32',
    emoji: '🇧🇪',
    value: 'BE',
  },
  {
    label: 'Belize (+501)',
    dial_code: '+501',
    emoji: '🇧🇿',
    value: 'BZ',
  },
  {
    label: 'Benin (+229)',
    dial_code: '+229',
    emoji: '🇧🇯',
    value: 'BJ',
  },
  {
    label: 'Bermuda (+1441)',
    dial_code: '+1441',
    emoji: '🇧🇲',
    value: 'BM',
  },
  {
    label: 'Bhutan (+975)',
    dial_code: '+975',
    emoji: '🇧🇹',
    value: 'BT',
  },
  {
    label: 'Bolivia, Plurinational State of (+591)',
    dial_code: '+591',
    emoji: '🇧🇴',
    value: 'BO',
  },
  {
    label: 'Bosnia and Herzegovina (+387)',
    dial_code: '+387',
    emoji: '🇧🇦',
    value: 'BA',
  },
  {
    label: 'Botswana (+267)',
    dial_code: '+267',
    emoji: '🇧🇼',
    value: 'BW',
  },
  {
    label: 'Brazil (+55)',
    dial_code: '+55',
    emoji: '🇧🇷',
    value: 'BR',
  },
  {
    label: 'British Indian Ocean Territory (+246)',
    dial_code: '+246',
    emoji: '🇮🇴',
    value: 'IO',
  },
  {
    label: 'Brunei Darussalam (+673)',
    dial_code: '+673',
    emoji: '🇧🇳',
    value: 'BN',
  },
  {
    label: 'Bulgaria (+359)',
    dial_code: '+359',
    emoji: '🇧🇬',
    value: 'BG',
  },
  {
    label: 'Burkina Faso (+226)',
    dial_code: '+226',
    emoji: '🇧🇫',
    value: 'BF',
  },
  {
    label: 'Burundi (+257)',
    dial_code: '+257',
    emoji: '🇧🇮',
    value: 'BI',
  },
  {
    label: 'Cambodia (+855)',
    dial_code: '+855',
    emoji: '🇰🇭',
    value: 'KH',
  },
  {
    label: 'Cameroon (+237)',
    dial_code: '+237',
    emoji: '🇨🇲',
    value: 'CM',
  },
  {
    label: 'Canada (+1)',
    dial_code: '+1',
    emoji: '🇨🇦',
    value: 'CA',
  },
  {
    label: 'Cape Verde (+238)',
    dial_code: '+238',
    emoji: '🇨🇻',
    value: 'CV',
  },
  {
    label: 'Cayman Islands (+345)',
    dial_code: '+345',
    emoji: '🇰🇾',
    value: 'KY',
  },
  {
    label: 'Central African Republic (+236)',
    dial_code: '+236',
    emoji: '🇨🇫',
    value: 'CF',
  },
  {
    label: 'Chad (+235)',
    dial_code: '+235',
    emoji: '🇹🇩',
    value: 'TD',
  },
  {
    label: 'Chile (+56)',
    dial_code: '+56',
    emoji: '🇨🇱',
    value: 'CL',
  },
  {
    label: 'China (+86)',
    dial_code: '+86',
    emoji: '🇨🇳',
    value: 'CN',
  },
  {
    label: 'Christmas Island (+61)',
    dial_code: '+61',
    emoji: '🇨🇽',
    value: 'CX',
  },
  {
    label: 'Cocos (Keeling) Islands (+61)',
    dial_code: '+61',
    emoji: '🇨🇨',
    value: 'CC',
  },
  {
    label: 'Colombia (+57)',
    dial_code: '+57',
    emoji: '🇨🇴',
    value: 'CO',
  },
  {
    label: 'Comoros (+269)',
    dial_code: '+269',
    emoji: '🇰🇲',
    value: 'KM',
  },
  {
    label: 'Congo (+242)',
    dial_code: '+242',
    emoji: '🇨🇬',
    value: 'CG',
  },
  {
    label: 'Congo, The Democratic Republic of the Congo (+243)',
    dial_code: '+243',
    emoji: '🇨🇩',
    value: 'CD',
  },
  {
    label: 'Cook Islands (+682)',
    dial_code: '+682',
    emoji: '🇨🇰',
    value: 'CK',
  },
  {
    label: 'Costa Rica (+506)',
    dial_code: '+506',
    emoji: '🇨🇷',
    value: 'CR',
  },
  {
    label: "Cote d'Ivoire (+225)",
    dial_code: '+225',
    emoji: '🇨🇮',
    value: 'CI',
  },
  {
    label: 'Croatia (+385)',
    dial_code: '+385',
    emoji: '🇭🇷',
    value: 'HR',
  },
  {
    label: 'Cuba (+53)',
    dial_code: '+53',
    emoji: '🇨🇺',
    value: 'CU',
  },
  {
    label: 'Cyprus (+357)',
    dial_code: '+357',
    emoji: '🇨🇾',
    value: 'CY',
  },
  {
    label: 'Czech Republic (+420)',
    dial_code: '+420',
    emoji: '🇨🇿',
    value: 'CZ',
  },
  {
    label: 'Denmark (+45)',
    dial_code: '+45',
    emoji: '🇩🇰',
    value: 'DK',
  },
  {
    label: 'Djibouti (+253)',
    dial_code: '+253',
    emoji: '🇩🇯',
    value: 'DJ',
  },
  {
    label: 'Dominica (+1767)',
    dial_code: '+1767',
    emoji: '🇩🇲',
    value: 'DM',
  },
  {
    label: 'Dominican Republic (+1849)',
    dial_code: '+1849',
    emoji: '🇩🇴',
    value: 'DO',
  },
  {
    label: 'Ecuador (+593)',
    dial_code: '+593',
    emoji: '🇪🇨',
    value: 'EC',
  },
  {
    label: 'Egypt (+20)',
    dial_code: '+20',
    emoji: '🇪🇬',
    value: 'EG',
  },
  {
    label: 'El Salvador (+503)',
    dial_code: '+503',
    emoji: '🇸🇻',
    value: 'SV',
  },
  {
    label: 'Equatorial Guinea (+240)',
    dial_code: '+240',
    emoji: '🇬🇶',
    value: 'GQ',
  },
  {
    label: 'Eritrea (+291)',
    dial_code: '+291',
    emoji: '🇪🇷',
    value: 'ER',
  },
  {
    label: 'Estonia (+372)',
    dial_code: '+372',
    emoji: '🇪🇪',
    value: 'EE',
  },
  {
    label: 'Ethiopia (+251)',
    dial_code: '+251',
    emoji: '🇪🇹',
    value: 'ET',
  },
  {
    label: 'Falkland Islands (Malvinas) (+500)',
    dial_code: '+500',
    emoji: '🇫🇰',
    value: 'FK',
  },
  {
    label: 'Faroe Islands (+298)',
    dial_code: '+298',
    emoji: '🇫🇴',
    value: 'FO',
  },
  {
    label: 'Fiji (+679)',
    dial_code: '+679',
    emoji: '🇫🇯',
    value: 'FJ',
  },
  {
    label: 'Finland (+358)',
    dial_code: '+358',
    emoji: '🇫🇮',
    value: 'FI',
  },
  {
    label: 'France (+33)',
    dial_code: '+33',
    emoji: '🇫🇷',
    value: 'FR',
  },
  {
    label: 'French Guiana (+594)',
    dial_code: '+594',
    emoji: '🇬🇫',
    value: 'GF',
  },
  {
    label: 'French Polynesia (+689)',
    dial_code: '+689',
    emoji: '🇵🇫',
    value: 'PF',
  },
  {
    label: 'Gabon (+241)',
    dial_code: '+241',
    emoji: '🇬🇦',
    value: 'GA',
  },
  {
    label: 'Gambia (+220)',
    dial_code: '+220',
    emoji: '🇬🇲',
    value: 'GM',
  },
  {
    label: 'Georgia (+995)',
    dial_code: '+995',
    emoji: '🇬🇪',
    value: 'GE',
  },
  {
    label: 'Germany (+49)',
    dial_code: '+49',
    emoji: '🇩🇪',
    value: 'DE',
  },
  {
    label: 'Ghana (+233)',
    dial_code: '+233',
    emoji: '🇬🇭',
    value: 'GH',
  },
  {
    label: 'Gibraltar (+350)',
    dial_code: '+350',
    emoji: '🇬🇮',
    value: 'GI',
  },
  {
    label: 'Greece (+30)',
    dial_code: '+30',
    emoji: '🇬🇷',
    value: 'GR',
  },
  {
    label: 'Greenland (+299)',
    dial_code: '+299',
    emoji: '🇬🇱',
    value: 'GL',
  },
  {
    label: 'Grenada (+1473)',
    dial_code: '+1473',
    emoji: '🇬🇩',
    value: 'GD',
  },
  {
    label: 'Guadeloupe (+590)',
    dial_code: '+590',
    emoji: '🇬🇵',
    value: 'GP',
  },
  {
    label: 'Guam (+1671)',
    dial_code: '+1671',
    emoji: '🇬🇺',
    value: 'GU',
  },
  {
    label: 'Guatemala (+502)',
    dial_code: '+502',
    emoji: '🇬🇹',
    value: 'GT',
  },
  {
    label: 'Guernsey (+44)',
    dial_code: '+44',
    emoji: '🇬🇬',
    value: 'GG',
  },
  {
    label: 'Guinea (+224)',
    dial_code: '+224',
    emoji: '🇬🇳',
    value: 'GN',
  },
  {
    label: 'Guinea-Bissau (+245)',
    dial_code: '+245',
    emoji: '🇬🇼',
    value: 'GW',
  },
  {
    label: 'Guyana (+595)',
    dial_code: '+595',
    emoji: '🇬🇾',
    value: 'GY',
  },
  {
    label: 'Haiti (+509)',
    dial_code: '+509',
    emoji: '🇭🇹',
    value: 'HT',
  },
  {
    label: 'Holy See (Vatican City State) (+379)',
    dial_code: '+379',
    emoji: '🇻🇦',
    value: 'VA',
  },
  {
    label: 'Honduras (+504)',
    dial_code: '+504',
    emoji: '🇭🇳',
    value: 'HN',
  },
  {
    label: 'Hong Kong (+852)',
    dial_code: '+852',
    emoji: '🇭🇰',
    value: 'HK',
  },
  {
    label: 'Hungary (+36)',
    dial_code: '+36',
    emoji: '🇭🇺',
    value: 'HU',
  },
  {
    label: 'Iceland (+354)',
    dial_code: '+354',
    emoji: '🇮🇸',
    value: 'IS',
  },
  {
    label: 'India (+91)',
    dial_code: '+91',
    emoji: '🇮🇳',
    value: 'IN',
  },
  {
    label: 'Indonesia (+62)',
    dial_code: '+62',
    emoji: '🇮🇩',
    value: 'ID',
  },
  {
    label: 'Iran, Islamic Republic of Persian Gulf (+98)',
    dial_code: '+98',
    emoji: '🇮🇷',
    value: 'IR',
  },
  {
    label: 'Iraq (+964)',
    dial_code: '+964',
    emoji: '🇮🇷',
    value: 'IQ',
  },
  {
    label: 'Ireland (+353)',
    dial_code: '+353',
    emoji: '🇮🇪',
    value: 'IE',
  },
  {
    label: 'Isle of Man (+44)',
    dial_code: '+44',
    emoji: '🇮🇲',
    value: 'IM',
  },
  {
    label: 'Israel (+972)',
    dial_code: '+972',
    emoji: '🇮🇱',
    value: 'IL',
  },
  {
    label: 'Italy (+39)',
    dial_code: '+39',
    emoji: '🇮🇹',
    value: 'IT',
  },
  {
    label: 'Jamaica (+1876)',
    dial_code: '+1876',
    emoji: '🇯🇲',
    value: 'JM',
  },
  {
    label: 'Japan (+81)',
    dial_code: '+81',
    emoji: '🇯🇵',
    value: 'JP',
  },
  {
    label: 'Jersey (+44)',
    dial_code: '+44',
    emoji: '🇯🇪',
    value: 'JE',
  },
  {
    label: 'Jordan (+962)',
    dial_code: '+962',
    emoji: '🇯🇴',
    value: 'JO',
  },
  {
    label: 'Kazakhstan (+77)',
    dial_code: '+77',
    emoji: '🇰🇿',
    value: 'KZ',
  },
  {
    label: 'Kenya (+254)',
    dial_code: '+254',
    emoji: '🇰🇪',
    value: 'KE',
  },
  {
    label: 'Kiribati (+686)',
    dial_code: '+686',
    emoji: '🇰🇮',
    value: 'KI',
  },
  {
    label: "Korea, Democratic People's Republic of Korea (+850)",
    dial_code: '+850',
    emoji: '🇰🇵',
    value: 'KP',
  },
  {
    label: 'Korea, Republic of South Korea (+82)',
    dial_code: '+82',
    emoji: '🇰🇷',
    value: 'KR',
  },
  {
    label: 'Kuwait (+965)',
    dial_code: '+965',
    emoji: '🇰🇼',
    value: 'KW',
  },
  {
    label: 'Kyrgyzstan (+996)',
    dial_code: '+996',
    emoji: '🇰🇬',
    value: 'KG',
  },
  {
    label: 'Laos (+856)',
    dial_code: '+856',
    emoji: '🇱🇦',
    value: 'LA',
  },
  {
    label: 'Latvia (+371)',
    dial_code: '+371',
    emoji: '🇱🇻',
    value: 'LV',
  },
  {
    label: 'Lebanon (+961)',
    dial_code: '+961',
    emoji: '🇱🇧',
    value: 'LB',
  },
  {
    label: 'Lesotho (+266)',
    dial_code: '+266',
    emoji: '🇱🇸',
    value: 'LS',
  },
  {
    label: 'Liberia (+231)',
    dial_code: '+231',
    emoji: '🇱🇷',
    value: 'LR',
  },
  {
    label: 'Libyan Arab Jamahiriya (+218)',
    dial_code: '+218',
    emoji: '🇱🇾',
    value: 'LY',
  },
  {
    label: 'Liechtenstein (+423)',
    dial_code: '+423',
    emoji: '🇱🇮',
    value: 'LI',
  },
  {
    label: 'Lithuania (+370)',
    dial_code: '+370',
    emoji: '🇱🇹',
    value: 'LT',
  },
  {
    label: 'Luxembourg (+352)',
    dial_code: '+352',
    emoji: '🇱🇺',
    value: 'LU',
  },
  {
    label: 'Macao (+853)',
    dial_code: '+853',
    emoji: '🇲🇴',
    value: 'MO',
  },
  {
    label: 'Macedonia (+389)',
    dial_code: '+389',
    emoji: '🇲🇰',
    value: 'MK',
  },
  {
    label: 'Madagascar (+261)',
    dial_code: '+261',
    emoji: '🇲🇬',
    value: 'MG',
  },
  {
    label: 'Malawi (+265)',
    dial_code: '+265',
    emoji: '🇲🇼',
    value: 'MW',
  },
  {
    label: 'Malaysia (+60)',
    dial_code: '+60',
    emoji: '🇲🇾',
    value: 'MY',
  },
  {
    label: 'Maldives (+960)',
    dial_code: '+960',
    emoji: '🇲🇻',
    value: 'MV',
  },
  {
    label: 'Mali (+223)',
    dial_code: '+223',
    emoji: '🇲🇱',
    value: 'ML',
  },
  {
    label: 'Malta (+356)',
    dial_code: '+356',
    emoji: '🇲🇹',
    value: 'MT',
  },
  {
    label: 'Marshall Islands (+692)',
    dial_code: '+692',
    emoji: '🇲🇭',
    value: 'MH',
  },
  {
    label: 'Martinique (+596)',
    dial_code: '+596',
    emoji: '🇲🇶',
    value: 'MQ',
  },
  {
    label: 'Mauritania (+222)',
    dial_code: '+222',
    emoji: '🇲🇷',
    value: 'MR',
  },
  {
    label: 'Mauritius (+230)',
    dial_code: '+230',
    emoji: '🇲🇺',
    value: 'MU',
  },
  {
    label: 'Mayotte (+262)',
    dial_code: '+262',
    emoji: '🇾🇹',
    value: 'YT',
  },
  {
    label: 'Mexico (+52)',
    dial_code: '+52',
    emoji: '🇲🇽',
    value: 'MX',
  },
  {
    label: 'Micronesia, Federated States of Micronesia (+691)',
    dial_code: '+691',
    emoji: '🇫🇲',
    value: 'FM',
  },
  {
    label: 'Moldova (+373)',
    dial_code: '+373',
    emoji: '🇲🇩',
    value: 'MD',
  },
  {
    label: 'Monaco (+377)',
    dial_code: '+377',
    emoji: '🇲🇨',
    value: 'MC',
  },
  {
    label: 'Mongolia (+976)',
    dial_code: '+976',
    emoji: '🇲🇳',
    value: 'MN',
  },
  {
    label: 'Montenegro (+382)',
    dial_code: '+382',
    emoji: '🇲🇪',
    value: 'ME',
  },
  {
    label: 'Montserrat (+1664)',
    dial_code: '+1664',
    emoji: '🇲🇸',
    value: 'MS',
  },
  {
    label: 'Morocco (+212)',
    dial_code: '+212',
    emoji: '🇲🇦',
    value: 'MA',
  },
  {
    label: 'Mozambique (+258)',
    dial_code: '+258',
    emoji: '🇲🇿',
    value: 'MZ',
  },
  {
    label: 'Myanmar (+95)',
    dial_code: '+95',
    emoji: '🇲🇲',
    value: 'MM',
  },
  {
    name: 'Namibia',
    labeli: '🇳🇦 (+264)',
    dial_code: '+264',
    code: 'NA',
  },
  {
    label: 'Nauru (+674)',
    dial_code: '+674',
    emoji: '🇳🇷',
    value: 'NR',
  },
  {
    label: 'Nepal (+977)',
    dial_code: '+977',
    emoji: '🇳🇵',
    value: 'NP',
  },
  {
    label: 'Netherlands (+31)',
    dial_code: '+31',
    emoji: '🇳🇱',
    value: 'NL',
  },
  {
    label: 'Netherlands Antilles (+599)',
    dial_code: '+599',
    emoji: '🇧🇶',
    value: 'AN',
  },
  {
    label: 'New Caledonia (+687)',
    dial_code: '+687',
    emoji: '🇳🇨',
    value: 'NC',
  },
  {
    label: 'New Zealand (+64)',
    dial_code: '+64',
    emoji: '🇳🇿',
    value: 'NZ',
  },
  {
    label: 'Nicaragua (+505)',
    dial_code: '+505',
    emoji: '🇳🇮',
    value: 'NI',
  },
  {
    label: 'Niger (+227)',
    dial_code: '+227',
    emoji: '🇳🇪',
    value: 'NE',
  },
  {
    label: 'Nigeria (+234)',
    dial_code: '+234',
    emoji: '🇳🇬',
    value: 'NG',
  },
  {
    label: 'Niue (+683)',
    dial_code: '+683',
    emoji: '🇳🇺',
    value: 'NU',
  },
  {
    label: 'Norfolk Island (+672)',
    dial_code: '+672',
    emoji: '🇳🇫',
    value: 'NF',
  },
  {
    label: 'Northern Mariana Islands (+1670)',
    dial_code: '+1670',
    emoji: '🇲🇵',
    value: 'MP',
  },
  {
    label: 'Norway (+47)',
    dial_code: '+47',
    emoji: '🇳🇴',
    value: 'NO',
  },
  {
    label: 'Oman (+968)',
    dial_code: '+968',
    emoji: '🇴🇲',
    value: 'OM',
  },
  {
    label: 'Pakistan (+92)',
    dial_code: '+92',
    emoji: '🇵🇰',
    value: 'PK',
  },
  {
    label: 'Palau (+680)',
    dial_code: '+680',
    emoji: '🇵🇼',
    value: 'PW',
  },
  {
    label: 'Palestinian Territory, Occupied (+970)',
    dial_code: '+970',
    emoji: '🇵🇸',
    value: 'PS',
  },
  {
    label: 'Panama (+507)',
    dial_code: '+507',
    emoji: '🇵🇦',
    value: 'PA',
  },
  {
    label: 'Papua New Guinea (+675)',
    dial_code: '+675',
    emoji: '🇵🇬',
    value: 'PG',
  },
  {
    label: 'Paraguay (+595)',
    dial_code: '+595',
    emoji: '🇵🇾',
    value: 'PY',
  },
  {
    label: 'Peru (+51)',
    dial_code: '+51',
    emoji: '🇵🇪',
    value: 'PE',
  },
  {
    label: 'Philippines (+63)',
    dial_code: '+63',
    emoji: '🇵🇭',
    value: 'PH',
  },
  {
    label: 'Pitcairn (+872)',
    dial_code: '+872',
    emoji: '🇵🇳',
    value: 'PN',
  },
  {
    label: 'Poland (+48)',
    dial_code: '+48',
    emoji: '🇵🇱',
    value: 'PL',
  },
  {
    label: 'Portugal (+351)',
    dial_code: '+351',
    emoji: '🇵🇹',
    value: 'PT',
  },
  {
    label: 'Puerto Rico (+1939)',
    dial_code: '+1939',
    emoji: '🇵🇷',
    value: 'PR',
  },
  {
    label: 'Qatar (+974)',
    dial_code: '+974',
    emoji: '🇶🇦',
    value: 'QA',
  },
  {
    label: 'Romania (+40)',
    dial_code: '+40',
    emoji: '🇷🇴',
    value: 'RO',
  },
  {
    label: 'Russia (+7)',
    dial_code: '+7',
    emoji: '🇷🇺',
    value: 'RU',
  },
  {
    label: 'Rwanda (+250)',
    dial_code: '+250',
    emoji: '🇷🇼',
    value: 'RW',
  },
  {
    label: 'Reunion (+262)',
    dial_code: '+262',
    emoji: '🇷🇪',
    value: 'RE',
  },
  {
    label: 'Saint Barthelemy (+590)',
    dial_code: '+590',
    emoji: '🇧🇱',
    value: 'BL',
  },
  {
    label: 'Saint Helena, Ascension and Tristan Da Cunha (+290)',
    dial_code: '+290',
    emoji: '🇸🇭',
    value: 'SH',
  },
  {
    label: 'Saint Kitts and Nevis (+1869)',
    dial_code: '+1869',
    emoji: '🇰🇳',
    value: 'KN',
  },
  {
    label: 'Saint Lucia (+1758)',
    dial_code: '+1758',
    emoji: '🇱🇨',
    value: 'LC',
  },
  {
    label: 'Saint Martin (+590)',
    dial_code: '+590',
    emoji: '🇲🇫',
    value: 'MF',
  },
  {
    label: 'Saint Pierre and Miquelon (+508)',
    dial_code: '+508',
    emoji: '🇵🇲',
    value: 'PM',
  },
  {
    label: 'Saint Vincent and the Grenadines (+1784)',
    dial_code: '+1784',
    emoji: '🇻🇨',
    value: 'VC',
  },
  {
    label: 'Samoa (+685)',
    dial_code: '+685',
    emoji: '🇼🇸',
    value: 'WS',
  },
  {
    label: 'San Marino (+378)',
    dial_code: '+378',
    emoji: '🇸🇲',
    value: 'SM',
  },
  {
    label: 'Sao Tome and Principe (+239)',
    dial_code: '+239',
    emoji: '🇸🇹',
    value: 'ST',
  },
  {
    label: 'Saudi Arabia (+966)',
    dial_code: '+966',
    emoji: '🇸🇦',
    value: 'SA',
  },
  {
    label: 'Senegal (+221)',
    dial_code: '+221',
    emoji: '🇸🇳',
    value: 'SN',
  },
  {
    label: 'Serbia (+381)',
    dial_code: '+381',
    emoji: '🇷🇸',
    value: 'RS',
  },
  {
    label: 'Seychelles (+248)',
    dial_code: '+248',
    emoji: '🇸🇨',
    value: 'SC',
  },
  {
    label: 'Sierra Leone (+232)',
    dial_code: '+232',
    emoji: '🇸🇱',
    value: 'SL',
  },
  {
    label: 'Singapore (+65)',
    dial_code: '+65',
    emoji: '🇸🇬',
    value: 'SG',
  },
  {
    label: 'Slovakia (+421)',
    dial_code: '+421',
    emoji: '🇸🇰',
    value: 'SK',
  },
  {
    label: 'Slovenia (+386)',
    dial_code: '+386',
    emoji: '🇸🇮',
    value: 'SI',
  },
  {
    label: 'Solomon Islands (+677)',
    dial_code: '+677',
    emoji: '🇸🇧',
    value: 'SB',
  },
  {
    label: 'Somalia (+252)',
    dial_code: '+252',
    emoji: '🇸🇴',
    value: 'SO',
  },
  {
    label: 'South Africa (+27)',
    dial_code: '+27',
    emoji: '🇿🇦',
    value: 'ZA',
  },
  {
    label: 'South Sudan (+211)',
    dial_code: '+211',
    emoji: '🇸🇸',
    value: 'SS',
  },
  {
    label: 'South Georgia and the South Sandwich Islands (+500)',
    dial_code: '+500',
    emoji: '🇬🇸',
    value: 'GS',
  },
  {
    label: 'Spain (+34)',
    dial_code: '+34',
    emoji: '🇪🇸',
    value: 'ES',
  },
  {
    label: 'Sri Lanka (+94)',
    dial_code: '+94',
    emoji: '🇱🇰',
    value: 'LK',
  },
  {
    label: 'Sudan (+249)',
    dial_code: '+249',
    emoji: '🇸🇩',
    value: 'SD',
  },
  {
    label: 'Suriname (+597)',
    dial_code: '+597',
    emoji: '🇸🇷',
    value: 'SR',
  },
  {
    label: 'Svalbard and Jan Mayen (+47)',
    dial_code: '+47',
    emoji: '🇸🇯',
    value: 'SJ',
  },
  {
    label: 'Swaziland (+268)',
    dial_code: '+268',
    emoji: '🇸🇿',
    value: 'SZ',
  },
  {
    label: 'Sweden (+46)',
    dial_code: '+46',
    emoji: '🇸🇪',
    value: 'SE',
  },
  {
    label: 'Switzerland (+41)',
    dial_code: '+41',
    emoji: '🇨🇭',
    value: 'CH',
  },
  {
    label: 'Syrian Arab Republic (+963)',
    dial_code: '+963',
    emoji: '🇸🇾',
    value: 'SY',
  },
  {
    label: 'Taiwan (+886)',
    dial_code: '+886',
    emoji: '🇹🇼',
    value: 'TW',
  },
  {
    label: 'Tajikistan (+992)',
    dial_code: '+992',
    emoji: '🇹🇯',
    value: 'TJ',
  },
  {
    label: 'Tanzania, United Republic of Tanzania (+255)',
    dial_code: '+255',
    emoji: '🇹🇿',
    value: 'TZ',
  },
  {
    label: 'Thailand (+66)',
    dial_code: '+66',
    emoji: '🇹🇭',
    value: 'TH',
  },
  {
    label: 'Timor-Leste (+670)',
    dial_code: '+670',
    emoji: '🇹🇱',
    value: 'TL',
  },
  {
    label: 'Togo (+228)',
    dial_code: '+228',
    emoji: '🇹🇬',
    value: 'TG',
  },
  {
    label: 'Tokelau (+690)',
    dial_code: '+690',
    emoji: '🇹🇰',
    value: 'TK',
  },
  {
    label: 'Tonga (+676)',
    dial_code: '+676',
    emoji: '🇹🇴',
    value: 'TO',
  },
  {
    label: 'Trinidad and Tobago (+1868)',
    dial_code: '+1868',
    emoji: '🇹🇹',
    value: 'TT',
  },
  {
    label: 'Tunisia (+216)',
    dial_code: '+216',
    emoji: '🇹🇳',
    value: 'TN',
  },
  {
    label: 'Turkey (+90)',
    dial_code: '+90',
    emoji: '🇹🇷',
    value: 'TR',
  },
  {
    label: 'Turkmenistan (+993)',
    dial_code: '+993',
    emoji: '🇹🇲',
    value: 'TM',
  },
  {
    label: 'Turks and Caicos Islands (+1649)',
    dial_code: '+1649',
    emoji: '🇹🇨',
    value: 'TC',
  },
  {
    label: 'Tuvalu (+688)',
    dial_code: '+688',
    emoji: '🇹🇻',
    value: 'TV',
  },
  {
    label: 'Uganda (+256)',
    dial_code: '+256',
    emoji: '🇺🇬',
    value: 'UG',
  },
  {
    label: 'Ukraine (+380)',
    dial_code: '+380',
    emoji: '🇺🇦',
    value: 'UA',
  },
  {
    label: 'United Arab Emirates (+971)',
    dial_code: '+971',
    emoji: '🇦🇪',
    value: 'AE',
  },
  {
    label: 'United Kingdom (+44)',
    dial_code: '+44',
    emoji: '🇬🇧',
    value: 'GB',
  },
  {
    label: 'United States (+1)',
    dial_code: '+1',
    emoji: '🇺🇸',
    value: 'US',
  },
  {
    label: 'Uruguay (+598)',
    dial_code: '+598',
    emoji: '🇺🇾',
    value: 'UY',
  },
  {
    label: 'Uzbekistan (+998)',
    dial_code: '+998',
    emoji: '🇺🇿',
    value: 'UZ',
  },
  {
    label: 'Vanuatu (+678)',
    dial_code: '+678',
    emoji: '🇻🇺',
    value: 'VU',
  },
  {
    label: 'Venezuela, Bolivarian Republic of Venezuela (+58)',
    dial_code: '+58',
    emoji: '🇻🇪',
    value: 'VE',
  },
  {
    label: 'Vietnam (+84)',
    dial_code: '+84',
    emoji: '🇻🇳',
    value: 'VN',
  },
  {
    label: 'Virgin Islands, British (+1284)',
    dial_code: '+1284',
    emoji: '🇻🇬',
    value: 'VG',
  },
  {
    label: 'Virgin Islands, U.S. (+1340)',
    dial_code: '+1340',
    emoji: '🇻🇮',
    value: 'VI',
  },
  {
    label: 'Wallis and Futuna (+681)',
    dial_code: '+681',
    emoji: '🇼🇫',
    value: 'WF',
  },
  {
    label: 'Yemen (+967)',
    dial_code: '+967',
    emoji: '🇾🇪',
    value: 'YE',
  },
  {
    label: 'Zambia (+260)',
    dial_code: '+260',
    emoji: '🇿🇲',
    value: 'ZM',
  },
  {
    label: 'Zimbabwe (+263)',
    dial_code: '+263',
    emoji: '🇿🇼',
    value: 'ZW',
  },
]

const stateList = [
  {value: 'Abra', label: 'Abra'},
  {value: 'Agusan Del Norte', label: 'Agusan Del Norte'},
  {value: ' Agusan Del Sur', label: ' Agusan Del Sur'},
  {value: 'Aklan', label: 'Aklan'},
  {value: 'Bulacan', label: 'Bulacan'},
]

const currencyList = [
  {
    label: 'VND',
    value: 'VND',
  },
  {
    label: 'IDR',
    value: 'IDR',
  },
  {
    label: 'XOF',
    value: 'XOF',
  },
  {
    label: 'PHP',
    value: 'PHP',
  },
  {
    label: 'USD',
    value: 'USD',
  },
]

export {
  cityList,
  stateList,
  statusList,
  countryList,
  allcountryList,
  allCategory,
  defaultLogs,
  currencyList,
  unitListItem,
  defaultAlerts,
  defaultMessages,
  defaultUserInfos,
  messageFromClient,
  configCategoryMap,
}
